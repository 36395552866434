define('components/controller/lang',['require','jquery','helper','components/controller/event-bus','components/controller/state','json!../../../lang/en.json','json!../../../lang/de.json'],function(require) {
	'use strict';

	var $ 			= require('jquery'),
		__ 			= require('helper'),
		eventbus	= require('components/controller/event-bus'),
		ctrlState	= require('components/controller/state');

	return {
		supportedLanguages: {
			en: {
				name: 'English',
				def: require('json!../../../lang/en.json')
			},
			de: {
				name: 'Deutsch',
				def: require('json!../../../lang/de.json')
			}
		},
		cdbPackagePrefix: 'PVGPV_',

		/**
		 * Initializes the language controller object
		 *
		 * @return {object}	, the controller itself
		 */
		init: function() {
			var self = this;

			eventbus.subscribe('state-change-language', function(ev, newLang) {
				self.onStateChangeLanguage(newLang);
			});

			eventbus.subscribe('element-render-finish', function(ev, el) {
				self.onElementRenderFinish($(el));
			});

			return this;
		},

		/**
		 * Event handler for a user change of the current language - translates all language elements
		 * of the current DOM body
		 *
		 * @event  state-change-language
		 * @param  {string} newLang , the name of the new language - e.g. en / de
		 * @return {undefined}
		 */
		onStateChangeLanguage: function(newLang) {
			if (__.is(this.supportedLanguages[newLang])) {
				this.translateElement($('body'));
			}
			else {
				window.console.warn('The new language identifier could not be found: ' + newLang);
				return false;
			}
		},

		/**
		 * Event handler that translates all language elements of a newly rendered DOM element
		 *
		 * @event  element-render-finish
		 * @param  {object} $el , jquery wrapped DOM element
		 * @return {undefined}
		 */
		onElementRenderFinish: function($el) {
			this.translateElement($el);
		},

		/**
		 * Takes a jquery wrapped DOM element and replaces its inner content with the translation
		 * found in the elements 'data-i18n' attribute. If the given element has no such attribute,
		 * a search for all child elements is started and the method is called recursively on every
		 * element found.
		 *
		 * @param  {object} $el , jquery wrapped DOM element
		 * @return {undefined}
		 */
		translateElement: function($el) {
			var self = this,
				key = $el.attr('data-i18n');

			if (__.is(key)) {
				$el.text(this.translate(key));
			}
			else {
				$el.find('[data-i18n]').each(function() {
					self.translateElement($(this));
				});
			}
		},

		/**
		 * Does a lookup in the currently selected languages dictionary and returns the matching
		 * translation to the given key if available - otherwise the key itself is returned
		 *
		 * @param  {string} key , translation key
		 * @return {string}		, translation string
		 */
		translate: function(key) {
			var def,
				currentLanguage = this.getCurrentLanguage(),
				prefixedKey;

			// fallback: english language if the current states language is not supported
			if (!__.is(this.supportedLanguages[currentLanguage])) {
				currentLanguage = 'en';
			}

			// prepare access to the current languages definition file
			def = this.supportedLanguages[currentLanguage].def;

			// prepend the cdb prefix to the given key if not yet done
			if (key.slice(0, this.cdbPackagePrefix.length) !== this.cdbPackagePrefix) {
				prefixedKey = this.cdbPackagePrefix + key;
			}

			if (__.is(prefixedKey) && __.is(def[prefixedKey])) {
				return def[prefixedKey];
			}
			else if (__.is(def[key])) {
				return def[key];
			}
			else {
				window.console.warn('Translation key not found (' + currentLanguage + '): ' + key);
				return key;
			}
		},

		/**
		 * Shortcut for the internal translate method
		 *
		 * @param  {string} key , translation key
		 * @return {string}		, translation string
		 */
		_: function(key) {
			return this.translate(key);
		},

		/**
		 * Returns the currently set language, e.g. 'en' or 'de'
		 *
		 * @return {string}		, current language identifier
		 */
		getCurrentLanguage: function() {
			return ctrlState.getLanguage();
		},

		/**
		 * Returns a translation for a given column name
		 *
		 * Please note: the result depends on the current level state of the application
		 * (see the state controller for more informations)
		 *
		 * @param  {string} column 	, name of the column to translage
		 * @return {string}			, translation of column name
		 */
		getColumnName: function(column) {
			return this._(ctrlState.getLevel().toUpperCase() + '_PARAM_' + column.toUpperCase());
		}
	};
});
