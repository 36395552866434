define('components/controller/login',['require','jquery','helper','components/controller/event-bus','components/controller/state','text!components/view/login/viewport.html'],function(require) {
	'use strict';

	var $ 					= require('jquery'),
		__ 					= require('helper'),
		eventbus 			= require('components/controller/event-bus'),
		ctrlState			= require('components/controller/state'),
		loginViewportTpl 	= require('text!components/view/login/viewport.html');

	return {
		init: function() {
			var self = this;

			eventbus.subscribe('logout', function() {
				self.onLogout();
			});

			eventbus.subscribe('render-login-screen', function() {
				self.onRenderLoginScreen();
			});

			eventbus.subscribe('login-failure', function() {
				self.onLoginFailure();
			});

			return this;
		},

		onLoginBtnClick: function($btn, ev) {
			var name = $('#text-name').val(),
				password = $('#text-password').val();

			if (name.length > 0 && password.length > 0) {
				ev.preventDefault();

				this.disableLoginForm();

				// remember the last users name that tried to use the system, so previous settings
				// can be restored
				ctrlState.setUserName(name);

				// inform the backend about the user login
				eventbus.publish('login-user', {
					name: name,
					password: password
				});
			}
		},

		onLogout: function() {
			this.render(loginViewportTpl);
			this.enableLoginForm();
			this.addBtnHandler();
		},

		onRenderLoginScreen: function() {
			this.render(loginViewportTpl);
			this.enableLoginForm();
			this.addBtnHandler();
		},

		onLoginFailure: function() {
			this.enableLoginForm();

			// notify user, that he used a wrong input
			eventbus.publish('dialog-warning', 'ERROR_UNKNOWN_AUTH_TOKEN');
		},

		render: function(html) {
			var $viewport = $('#viewport');

			$viewport.html(__.def(html, ''));
			eventbus.publish('element-render-finish', $viewport);
		},

		enableLoginForm: function() {
			$('#form-login input').prop('disabled', false);
			$('#form-login button')
				.prop('disabled', false)
				.find('span')	// remove the loading notifier if available
				.remove();
		},

		disableLoginForm: function() {
			$('#form-login input').prop('disabled', true);
			$('#form-login button')
				.prop('disabled', true)
				.append('<span class="glyphicon glyphicon-refresh rotate360"></span>');
		},

		addBtnHandler: function() {
			var self = this;

			// add button event handler to the login button
			// please note: this button has no data-action attribute, hence it is not handled
			// by the generic button event handler defined in main.js
			$('#button-login').on('click', function(ev) {
				self.onLoginBtnClick($(this), ev);
			});
		}
	};
});
