/* global define, requirejs */
(function() {
	'use strict';

	var APPLICATIONNAMESPACE = '__PV__';

	requirejs.config({
		baseUrl: 'js',
		paths: {
			'jquery'			: '../../node_modules/jquery/dist/jquery.min',
			'bootstrap'			: '../../node_modules/bootstrap/dist/js/bootstrap.min',
			'bootstrap-notify'	: '../../node_modules/bootstrap-notify/bootstrap-notify.min',
			'bootstrap-switch'	: '../../node_modules/bootstrap-switch/dist/js/bootstrap-switch.min',
			'socketio'			: '../../node_modules/socket.io-client/dist/socket.io',
			'text'				: '../../node_modules/requirejs-text/text',
			'json'				: '../../node_modules/requirejs-json/json',
			'jscookie'			: '../../node_modules/js-cookie/src/js.cookie',
			'lzstring'			: '../../node_modules/lz-string/libs/lz-string.min',
			'helper'			: 'lib/skyweb-helper'
		},
		shim: {
			'bootstrap': {
				deps: [
					'jquery'
				]
			},
			'bootstrap-notify': {
				deps: [
					'bootstrap'
				]
			},
			'bootstrap-switch': {
				deps: [
					'bootstrap'
				]
			},
			'socketio': {
		      exports: 'io'
		    },
		    'json': {
		    	deps: [
		    		'text'
		    	]
		    }
		}
	});

	define('main',['require','components/controller/event-bus','components/controller/state','helper','jquery','bootstrap','bootstrap-notify','bootstrap-switch','components/controller/login','components/controller/communication','components/controller/dialog','components/controller/navbar','components/controller/table','components/controller/form','components/controller/theme','components/controller/lang','components/store/devices','components/store/plants','components/store/alarms'],function(require) {
		var eventbus 		= require('components/controller/event-bus'),
			ctrlState 		= require('components/controller/state'),
			__ 				= require('helper'),
			href			= window.location.href,
			urlParams		= {},
			bootstrapEvents	= [];

		// require needed dependencies that add themselves to the window object
		require('jquery');
		require('bootstrap');
		require('bootstrap-notify');
		require('bootstrap-switch');

		// register namespace for the portfolio view application
		window[APPLICATIONNAMESPACE] = {
			controller: {
				'event-bus' 	: eventbus,
				state 			: ctrlState.init(),
				login 			: require('components/controller/login').init(),
				communication	: require('components/controller/communication').init(),
				dialog 			: require('components/controller/dialog').init(),
				navbar			: require('components/controller/navbar').init(),
				table 			: require('components/controller/table').init(),
				form 			: require('components/controller/form').init(),
				theme 			: require('components/controller/theme').init(),
				lang			: require('components/controller/lang').init()
			},
			store: {
				devices 		: require('components/store/devices').init(),
				plants 			: require('components/store/plants').init(),
				alarms 			: require('components/store/alarms').init()
			},
			libs: {
				helper: __
			}
		};

		// create a reference in the state manager
		ctrlState.setApplicationNamespace(APPLICATIONNAMESPACE);

		// parse url parameters for later use and save them as current state
		if (href.indexOf('?') > 0) {
			href = href.split('?')[1].split('&');

			href.forEach(function(post) {
				post = post.split('=');
				urlParams[post[0]] = post[1];
			});
		}
		ctrlState.setUrlParams(urlParams);

		// bootstrap
		// please note: first of all we need to detect a changed server uri to inform the backend
		// to ensure that from the beginning every tx addresses the new server uri
		if (__.is(urlParams.skygate_url)) {
			eventbus.publish('changed-server-uri');
		}

		// second step: re-initialize state values from cookies or localstorage
		if (ctrlState.hasValidUserSettingsStored() === true) {
			ctrlState.restoreUserFromPersistence();
			ctrlState.restoreStateFromPersistence();
			bootstrapEvents.push('reconnect-user');
			bootstrapEvents.push('login-success');
		}

		// step three: process url parameters to update initialized state
		bootstrapEvents = __.addOnce(bootstrapEvents, ctrlState.applyUrlParams(urlParams));

		// default: make sure that the user sees at least the login screen
		if (bootstrapEvents.length === 0 ||
			(bootstrapEvents.indexOf('render-login-screen') === -1
				&& bootstrapEvents.indexOf('login-success') === -1)) {
			bootstrapEvents.push('render-login-screen');
		}

		// fire all events identified as necessary during the bootstrap procedure
		bootstrapEvents.forEach(function(ev) {
			eventbus.publish(ev);
		});
		// end of bootstrap
	});
})();

