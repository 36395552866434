define('components/controller/theme',['require','jquery','helper','components/controller/event-bus','components/controller/state'],function(require) {
	'use strict';

	var $ 			= require('jquery'),
		__			= require('helper'),
		eventbus 	= require('components/controller/event-bus'),
		ctrlState	= require('components/controller/state');


	return {
		themes: [
			'light-min',
			'dark-min',
			'light',
			'dark'
		],

		init: function() {
			var self = this;

			eventbus.subscribe('logout', function() {
				self.onLogout();
			});

			eventbus.subscribe('login-success', function() {
				self.onLoginSuccess();
			});

			eventbus.subscribe('element-render-finish', function(ev, el) {
				self.onElementRenderFinish($(el));
			});

			eventbus.subscribe('state-change-theme', function(ev, theme) {
				self.onStateChangeTheme(theme);
			});
		},

		/**
		 * Resets the current theme and initializes the new theme
		 *
		 * @event  state-change-theme
		 * @param  {string} theme , name of the theme
		 * @return {undefined}
		 */
		onStateChangeTheme: function(theme) {
			if (!__.is(theme)) {
				theme = ctrlState.getTheme();
			}

			this.resetTheme();
			$('body').addClass(theme);
		},

		/**
		 * Keeps the theme settings up to date if the user does a login
		 *
		 * @event  login-success
		 * @return {undefined}
		 */
		onLoginSuccess: function() {
			if (ctrlState.getState('mobileapp') === true) {
				this.enableMobileAppSettings();
			}
		},

		/**
		 * Starts the standard logout procedure, e.g. resetting the theme
		 *
		 * @event  logout
		 * @return {undefined}
		 */
		onLogout: function() {
			this.resetTheme();
		},

		/**
		 * Removes elements that are not needed if the mobileapp setting is true after rendering
		 * of the viewport is finished -> means that a new page was rendered
		 *
		 * @event  element-render-finish
		 * @param  {object} $el , jQuery DOM object
		 * @return {undefined}
		 */
		onElementRenderFinish: function($el) {
			if (($el.attr('id') === 'viewport' || $el.hasClass('navbar') === true)
				&& ctrlState.getMobileApp() === true) {
				this.enableMobileAppSettings();
				this.resetTheme();
				$('body').addClass(ctrlState.getTheme());
			}
		},

		/**
		 * Prepares the page to be more mobile friendly by removing all elements from the screen
		 * that are not necessary for an embedded page in an application for mobile devices
		 *
		 * Please note: should only be called if the url parameter mobileapp=true is set
		 *
		 * @return {undefined}
		 */
		enableMobileAppSettings: function() {
			$('#footer').remove();
			$('ul.nav a[data-action="navbar-open-logout"]').closest('li').remove();

			// remove reserved footer space from body to prevent rendering of a second scrollbar
			$('body').css('margin-bottom', 0);
			$('#spinner-wrapper').css('margin-bottom', '55px');

			// trigger a windows resize event to start the table controller event handler
			// for recalculating the table size
			$(window).trigger('resize');
		},

		/**
		 * Resets the page to a non-themed variant by removing all theme-dependant css classes
		 *
		 * @return {undefined}
		 */
		resetTheme: function() {
			var $body = $('body');

			this.themes.forEach(function(theme) {
				$body.removeClass(theme);
			});
		}
	};
});
