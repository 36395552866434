define('components/controller/communication',['require','socketio','components/controller/event-bus','components/controller/state','components/controller/lang','components/store/devices','components/store/plants','components/store/alarms','helper'],function(require) {
	'use strict';

	var io = require('socketio'),
		eventbus = require('components/controller/event-bus'),
		ctrlState = require('components/controller/state'),
		ctrlLang = require('components/controller/lang'),
		storeDevices = require('components/store/devices'),
		storePlants = require('components/store/plants'),
		storeAlarms = require('components/store/alarms'),
		__ = require('helper'),
		xmlParser = new window.DOMParser();

	return {
		socket: undefined,

		/**
		 * Initializes the communication controller that handles backend communication
		 *
		 * @return {undefined}
		 */
		init: function() {
			var self = this,
				socket = io.connect(),
				tablePreloadFn;

			this.socket = socket;

			this.socket.on('connect_error', function() {
				self.onSocketError();
			});

			socket.on('portfolioResponse', function(response) {
				self.onSocketPortfolioResponse(response.skyresponse, response.offset);
			});

			socket.on('aggregateResponse', function(response) {
				self.onSocketAggregateResponse(response.skyresponse);
			});

			socket.on('login', function(response) {
				self.onSocketLogin(response);
			});

			socket.on('userUnknown', function() {
				self.onSocketUserUnknown();
			});

			socket.on('serverError', function(message) {
				self.onSocketServerError(message);
			});

			eventbus.subscribe('user-logout', function() {
				self.onUserLogout();
			});

			eventbus.subscribe('logout', function() {
				self.onLogout();
			});

			eventbus.subscribe('changed-row-offset', function(ev, row) {
				self.onChangedRowOffset(row);
			});

			eventbus.subscribe('changed-row-offset-to-old-data', function(ev, row) {
				self.onChangedRowOffsetToOldData(row);
			});

			tablePreloadFn = __.debounce(function(ev, row) {
				self.onTablePreload(row);
			}, 500);
			eventbus.subscribe('table-preload', tablePreloadFn);

			eventbus.subscribe('update-sort-order', function(ev, message) {
				self.onUpdateSortOrder(message.column, message.order);
			});

			eventbus.subscribe('update-aggregation-mode', function(ev, message) {
				self.onUpdateAggregationMode(message.column, message.mode);
			});

			eventbus.subscribe('store-filters-update', function(ev, message) {
				self.onStoreFiltersUpdated(message.action, {
					column: message.column,
					operation: message.operation,
					value: message.value
				}, message.skipDataRefresh);
			});

			eventbus.subscribe('store-columns-update', function(ev, message) {
				self.onStoreColumnsUpdate(message.visibleColumns);
			});

			eventbus.subscribe('state-change-level', function(ev, message) {
				self.onStateChangeLevel(message);
			});

			eventbus.subscribe('login-user', function(ev, message) {
				self.onLoginUser(message.name, message.password);
			});

			eventbus.subscribe('reconnect-user', function() {
				self.onReconnectUser();
			});

			eventbus.subscribe('changed-server-uri', function() {
				self.onChangedServerUri();
			});

			return this;
		},

		/**
		 * Displays a warning message if the socket is lost
		 *
		 * @event  connect_error
		 * @return {undefined}
		 */
		onSocketError: function() {
			eventbus.publish('dialog-warning', ctrlLang._('ERROR_SOCKET_LOST'));

			// do a client logout if we are not already on the login page
			if (ctrlState.getState('page') !== 'login') {
				eventbus.publish('logout');
			}
		},

		/**
		 * Handles the incoming portfolio responses by either sending the data to the data controller
		 * or displaying an error message
		 *
		 * @event  portfolioResponse
		 * @param  {string} response , the portfolio backend response in XML format
		 * @param  {int} 	offset   , the current data offset
		 * @return {undefined}
		 */
		onSocketPortfolioResponse: function(response, offset) {
			var parsed = __.xmlToJson(xmlParser.parseFromString(response, 'text/xml')),
				status = parsed.skyresponse['@attributes'].status,
				errormessage,
				portfolio;

			if (__.is(parsed)
				&& __.is(parsed.skyresponse)
				&& __.is(parsed.skyresponse.getPortfolioStatus)) {
				errormessage = parsed.skyresponse.getPortfolioStatus.errormessage;
				portfolio = parsed.skyresponse.getPortfolioStatus.portfolio;
			}

			// if error / status warning is available - notify the user
			if (__.is(status)) {
				if (status === 'AUTH_TOKEN_EXPIRED') {
					status = ctrlLang._('AUTH_TOKEN_EXPIRED');
				}

				eventbus.publish('dialog-warning', status);
				eventbus.publish('logout');
			}
			else if (__.is(errormessage)) {
				eventbus.publish('dialog-warning', errormessage['#text']);
			}
			// otherwise distribute the data in the correct channel
			else if (__.is(portfolio)) {
				if (__.is(portfolio.device)) {
					eventbus.publish('new-device-data', {
						offset: offset,
						data: portfolio.device
					});
				}
				else if (__.is(portfolio.plant)) {
					eventbus.publish('new-plant-data', {
						offset: offset,
						data: portfolio.plant
					});
				}
				else if (__.is(portfolio.alarm)) {
					eventbus.publish('new-alarm-data', {
						offset: offset,
						data: portfolio.alarm
					});
				}
				else {
					eventbus.publish('dialog-warning', 'ERROR_PORTFOLIO_TYPE_UNSUPPORTED');
				}
			}
			// no status warning, but also no portfolio data -> check for a given offset, so we can
			// be sure, that the end of the current data list is reached
			else if (!__.is(portfolio) && offset > 0) {
				eventbus.publish('no-portfolio-data');
			}
			// issue 11511: design-by-contract - no portfolio block together with an offset of 0 means
			// "no data available" and leads to a deletion of the currently rendered table
			else if (!__.is(portfolio) && offset === 0) {
				eventbus.publish('reset-data');
			}
			// standard case: no data retrieved -> inform the user
			else {
				eventbus.publish('dialog-warning', 'ERROR_MISSING_DATA');
				eventbus.publish('no-portfolio-data');
			}
		},

		onSocketAggregateResponse: function(response) {
			var parsed = __.xmlToJson(xmlParser.parseFromString(response, 'text/xml')),
				status = parsed.skyresponse['@attributes'].status,
				errormessage,
				portfolio;

			if (__.is(parsed)
				&& __.is(parsed.skyresponse)
				&& __.is(parsed.skyresponse.getPortfolioStatus)) {
				errormessage = parsed.skyresponse.getPortfolioStatus.errormessage;
				portfolio = parsed.skyresponse.getPortfolioStatus.portfolio;
			}

			if (__.is(status)) {
				eventbus.publish('dialog-warning', status);
			}

			if (__.is(errormessage)) {
				eventbus.publish('dialog-warning', errormessage['#text']);
			}
			else if (__.is(portfolio)) {
				if (__.is(portfolio.plant)) {
					eventbus.publish('new-plant-aggregation', portfolio.plant);
				}
				else if (__.is(portfolio.device)) {
					eventbus.publish('new-device-aggregation', portfolio.device);
				}
				else if (__.is(portfolio.alarm)) {
					eventbus.publish('new-alarm-aggregation', portfolio.alarm);
				}
			}
			else {
				eventbus.publish('dialog-warning', 'ERROR_MISSING_DATA');
				eventbus.publish('no-aggregation-data');
			}
		},

		/**
		 * Notifies the system on an un-/succesful socket login
		 *
		 * @event  login
		 * @param  {object} response , parsed response XML string
		 * @return {undefined}
		 */
		onSocketLogin: function(response) {
			if (response.success === true) {
				eventbus.publish('login-success', {
					token: response.token,
					expireDate: response.expireDate
				});
			}
			else if (response.success === false) {
				eventbus.publish('login-failure');
			}
		},

		/**
		 * If an unknown user was detected the user is informed and the logout procedure is initiated
		 *
		 * @event  userUnknown
		 * @return {undefined}
		 */
		onSocketUserUnknown: function() {
			eventbus.publish('dialog-warning', 'ERROR_UNKNOWN_USER');
			eventbus.publish('logout');
		},

		/**
		 * Displays an error message from the server
		 *
		 * @param  {string} message , error message
		 * @return {undefined}
		 */
		onSocketServerError: function(message) {
			eventbus.publish('dialog-warning', message);
			eventbus.publish('logout');
		},

		onUserLogout: function() {
			// first we have to release the auth token on the backend
			this.socket.emit('cancelAuth');

			// afterwards initiate the standard logout procedure by informing the system
			eventbus.publish('logout');
		},

		onLogout: function() {
			this.socket.emit('logout');
		},

		/**
		 * Informs the backend, that the current tables row offset has been changed
		 *
		 * @event  changed-row-offset
		 * @param  {int} row , the current row offset as calculated by the table controller
		 * @return {undefined}
		 */
		onChangedRowOffset: function(row) {
			this.socket.emit('changedRowOffset', row);
		},

		/**
		 * Informs the backend, that the current tables row offset has been changed to a row with
		 * an outdated data value -> hence the data will be reloaded and pushed back to the client
		 *
		 * @event  changed-row-offset-to-old-data
		 * @param  {int} row , the current row offset as calculated by the table controller
		 * @return {undefined}
		 */
		onChangedRowOffsetToOldData: function(row) {
			this.socket.emit('changedRowOffsetToOldData', row);
		},

		/**
		 * Trigger a data update on the backend with a given row offset
		 *
		 * @event  table-preload
		 * @param  {int} row , offset of the wanted data list
		 * @return {undefined}
		 */
		onTablePreload: function(row) {
			this.socket.emit('tablePreload', row);
		},

		/**
		 * Informs the backend if the sort order was changed
		 *
		 * @event  update-sort-order
		 * @param  {string} column , name of the column where the sort order was changed
		 * @param  {string} order  , ASC / DESC / undefined
		 * @return {undefined}
		 */
		onUpdateSortOrder: function(column, order) {
			order = (__.is(order)) ? order.toLowerCase() : null;
			this.socket.emit('updateSortOrder', column, order);
		},

		onUpdateAggregationMode: function(column, mode) {
			this.socket.emit('updateAggregationMode', column, mode);
		},

		/**
		 * Informs the backend if the filter settings have been updated
		 *
		 * @event  store-filters-update
		 * @param  {string} action 				, add / remove
		 * @param  {object} filter 				, the filter object to be added or removed
		 * @param  {boolean} skipDataRefresh 	, indicator if the backend should initiate data refresh
		 * @return {undefined}
		 */
		onStoreFiltersUpdated: function(action, filter, skipDataRefresh) {
			var stores;

			if (action === 'remove') {
				this.socket.emit('removeFilter', filter);
			}
			else if (action === 'add') {
				this.socket.emit('addFilter', filter, skipDataRefresh);
			}
			else if (action === 'reset') {
				stores = {
					device: storeDevices,
					plant: storePlants,
					alarm: storeAlarms
				};

				this.socket.emit('resetFilter',
					ctrlState.getLevel(),
					stores[ctrlState.getLevel()].getColumns()
				);
			}
		},

		/**
		 * Informs the backend about a change of visible columns so the backend can adjust the
		 * set of retrieved properties - also triggers the data retrieval process afterwards
		 *
		 * @event  store-columns-update
		 * @param  {array} visibleColumns , list of visible column keys
		 * @return {undefined}
		 */
		onStoreColumnsUpdate: function(visibleColumns) {
			var level = ctrlState.getLevel();
			this.socket.emit('updateColumns', level, visibleColumns);
		},

		/**
		 * Informs the backend if the current state of the level has changed (e.g. device or plant)
		 *
		 * @event  state-change-level
		 * @param  {string} level , name of the new level
		 * @return {undefined}
		 */
		onStateChangeLevel: function(level) {
			this.socket.emit('changedLevel', level);
		},

		/**
		 * Tries to restore a former page state and updates the backend with those state settings after
		 * an already known user logged in successfully
		 *
		 * @event  login-user
		 * @param  {string} name     , name of the user
		 * @param  {string} password , password of the user
		 * @return {undefined}
		 */
		onLoginUser: function(name, password) {
			ctrlState.restoreStateFromPersistence();
			this.socket.emit('login',
				name,
				password,
				ctrlState.getLevel(),
				storeDevices.getColumns(),
				storePlants.getColumns(),
				storeAlarms.getColumns()
			);
		},

		/**
		 * Updates the backend with the current users settings after a user does a reconnect
		 *
		 * @event  reconnect-user
		 * @return {undefined}
		 */
		onReconnectUser: function() {
			this.socket.emit('reconnectUser',
				ctrlState.getUserName(),
				ctrlState.getAuthToken(),
				ctrlState.getLevel(),
				storeDevices.getColumns(),
				storePlants.getColumns(),
				storeAlarms.getColumns()
			);
		},

		/**
		 * Updates the current skygate backend URI with the new server URI
		 *
		 * @return {undefined}
		 */
		onChangedServerUri: function() {
			this.socket.emit('changedServerUri', ctrlState.getSkygateUrl());
		},

		/**
		 * Sends a given message/data tuple to the backend
		 *
		 * @param  {string} 			message , the name of the message
		 * @param  {string/int/object} 	data    , message specific data block
		 * @return {undefined}
		 */
		send: function(message, data) {
			this.socket.emit(message, data);
		}
	};
});
