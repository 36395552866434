define('components/store/plants',['require','components/controller/event-bus','components/controller/state','components/store/generic','helper'],function(require) {
	'use strict';

	var eventbus 		= require('components/controller/event-bus'),
		ctrlState		= require('components/controller/state'),
		GenericStore	= require('components/store/generic'),
		__				= require('helper'),
		store;

	// create new plants store from the generic store
	store = new GenericStore({
		level: 'plant',
		// possible column entry values:
		// visible (boolean), sortOrder (string), filters (array), standardFilters (array),
		// aggregate (string), ignoreAggregates (array)
		columns: {
			// ---- start of initially visible columns
			ALIAS: {
				visible: true,
				sortOrder: 'ASC',
				filters: []
			},
			YIELD_DAY_NORM: {
				visible: true,
				filters: [],
				aggregate: 'avg'
			},
			P_AC_NORM: {
				// percent value 0-1
				visible: true,
				filters: [],
				aggregate: 'avg'
			},
			INV_AVAIL: {
				// percent value 0-100
				visible: true,
				filters: [],
				aggregate: 'avg'
			},
			P_RAD: {
				visible: true,
				filters: [],
				aggregate: 'avg'
			},
			YIELD_WEEK_NORM: {
				visible: true,
				filters: [],
				aggregate: 'avg'
			},
			// ---- end of initially visible columns
			// ---- start of additional measurement values
			YIELD_DAY: {
				visible: false,
				filters: [],
				aggregate: 'sum'
			},
			YIELD_WEEK: {
				visible: false,
				filters: [],
				aggregate: 'sum'
			},
			FORECAST_YIELD_TODAY: {
				visible: false,
				filters: [],
				aggregate: 'sum'
			},
			FORECAST_YIELD_TOMORROW: {
				visible: false,
				filters: [],
				aggregate: 'sum'
			},
			P_AC_TOTAL: {
				visible: false,
				filters: [],
				aggregate: 'sum'
			},
			PR_DAY: {
				// percent value 0-100
				visible: false,
				filters: [],
				aggregate: 'avg'
			},
			EL_SUN: {
				visible: false,
				filters: [
					{
						operation: 'greater',
						value: 0
					}
				],
				// standard filter, which is initially set
				standardFilters: [
					{
						operation: 'greater',
						value: 0
					}
				],
				aggregate: 'max'
			},
			T_AMB: {
				visible: false,
				filters: [],
				aggregate: 'max'
			},
			T_MOD: {
				visible: false,
				filters: [],
				aggregate: 'max'
			},
			// ---- end of additional measurement values
			// ---- start of configuration parameters
			P_AC_NOMINAL: {
				visible: false,
				filters: [],
				aggregate: 'sum'
			},
			P_DC_INSTALLED: {
				visible: false,
				filters: [],
				aggregate: 'sum'
			},
			PLANT_TZ: {
				visible: false,
				filters: []
			},
			changed: {
				visible: false,
				filters: [],
				filterable: false
			},
			upid: {
				visible: false,
				filters: []
			}
			// ---- end of configuration parameters
		},

		init: function() {
			var self = this;

			this.cache = [];
			this.aggregates = {};

			eventbus.subscribe('logout', function() {
				self.onLogout();
			});

			eventbus.subscribe('new-plant-data', function(ev, message) {
				self.onNewPlantData(message.offset, message.data);
			});

			eventbus.subscribe('new-plant-aggregation', function(ev, data) {
				self.onNewStoreAggregation(data);
			});

			eventbus.subscribe('reset-data', function() {
				if (ctrlState.getLevel() === 'plant') {
					self.onResetData();
				}
			});

			eventbus.subscribe('add-filter', function(ev, message) {
				if (ctrlState.getLevel() === 'plant') {
					self.onAddFilter(message.selectColumn, message.selectOperation, message.inputValue,
						message.skipDataRefresh);
				}
			});

			eventbus.subscribe('remove-filter', function(ev, message) {
				if (ctrlState.getLevel() === 'plant') {
					self.onRemoveFilter(message.column, message.operation, message.value);
				}
			});

			eventbus.subscribe('reset-filter', function() {
				if (ctrlState.getLevel() === 'plant') {
					self.onResetFilter();
				}
			});

			eventbus.subscribe('change-column-settings', function(ev, data) {
				if (ctrlState.getLevel() === 'plant') {
					self.onChangeColumnSettings(data);
				}
			});

			eventbus.subscribe('state-change-level', function(ev, message) {
				self.onStateChangeLevel(message);
			});

			eventbus.subscribe('restore-plants-store', function(ev, columns) {
				self.onRestorePlantsStore(columns);
			});

			return this;
		},

		onNewPlantData: function(offset, data) {
			var i;

			// only process the new data if the current level state has not changed
			// please note: there could still be a new data set on the line while the
			// user changed the level of the data
			if (ctrlState.getLevel() !== 'plant') {
				return false;
			}

			if (offset === 0 && this.pendingSortFilter === true) {
				this.cache = [];
				this.pendingSortFilter = false;
			}

			// bugfix issue 11511: the xml parser translates single results into an object and
			// multiple results into an array of objects
			if (__.isArray(data)) {
				for (i = 0; i < data.length; i++) {
					this.cache[offset + i] = this.dataMap(data[i]);
				}
			}
			else {
				this.cache[offset] = this.dataMap(data);
			}

			eventbus.publish('store-update', {
				offset: offset,
				length: data.length || 1	// fallback if single object instead of array
			});
		},

		onResetData: function() {
			this.cache = [];
			this.aggregates = {};
			this.pendingSortFilter = false;

			eventbus.publish('store-update-data-reset');
		},

		onStateChangeLevel: function(level) {
			if (level !== 'plant') {
				// please note: we do not want a complete reset, because we want to keep the
				// sort order and filter settings synced with the backend, so we can re-build the
				// ui if the user switches back to the this level / store
				this.cache = [];
				this.pendingSortFilter = false;
			}
		},

		onRestorePlantsStore: function(columns) {
			if (this.isValidConfig(columns)) {
				this.columns = columns;
			}
			else if (__.is(window.console)) {
				window.console.warn('Tried to restore invalid plant config - resetting');
				eventbus.publish('invalid-store-config', 'plant');
			}
		},

		/**
		 * Transforms skygate result data into atomic website components, e.g. a percent value into
		 * a <span> element that displays a percentage bar
		 *
		 * @please note: called by store.generic.dataMap
		 *
		 * @param  {string} 	pId   				, property id
		 * @param  {string} 	pText 				, property text
		 * @return {string}       					, HTML representation
		 */
		transformProperty: function(pId, pText) {
			switch (pId) {
				// fields with only 2 decimal places
				case 'YIELD_WEEK':
				case 'YIELD_DAY_NORM':
				case 'YIELD_WEEK_NORM':
				case 'P_AC_TOTAL':
				case 'P_AC_NOMINAL':
				case 'P_DC_INSTALLED':
					return __.parseFloat(pText, 2);
				// fields with no decimal places
				case 'YIELD_DAY':
				case 'P_RAD':
				case 'EL_SUN':
				case 'T_AMB':
				case 'T_MOD':
					return __.parseFloat(pText, 0);
				// special fields with progress bar values
				case 'P_AC_NORM':
					pText = __.parseFloat(pText, 2);
					return this.getProgressBarValue(pText, 1);
				case 'PR_DAY':
					pText = __.parseFloat(pText, 0);
					return this.getProgressBarValue(pText, 100);
				case 'INV_AVAIL':
					pText = __.parseFloat(pText, 0);
					return this.getProgressBarValue(pText, 100);
				default:
					return pText;
			}
		},

		/**
		 * Transforms skygate aggregation data into atomic website components
		 *
		 * @please note: called by store.generic.onNewStoreAggregation
		 *
		 * @param  {string} pId   , property id
		 * @param  {string} pText , property text
		 * @return {string}       , HTML representation
		 */
		transformAggregateProperty: function(pId, pText) {
			switch (pId) {
				case 'YIELD_DAY':
				case 'YIELD_WEEK':
				case 'FORECAST_YIELD_TODAY':
				case 'FORECAST_YIELD_TOMORROW':
					return this.getShortestValue(pText, 'MWh', 'GWh');
				case 'P_AC_TOTAL':
				case 'P_AC_NOMINAL':
					return this.getShortestValue(pText, 'MW', 'GW');
				case 'P_DC_INSTALLED':
					return this.getShortestValue(pText, 'MWp', 'GWp');
				// as default we return the standard property transformation
				default:
					return this.transformProperty(pId, pText);
			}
		}
	});

	// expose the store object as module
	return store;
});

