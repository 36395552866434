define('components/controller/event-bus',['jquery'], function($) {
	'use strict';

	return {
		/**
		 * Subscribe to a given event, calling the given function afterwards
		 *
		 * @param  {string}   event , event name
		 * @param  {Function} fn    , caller function
		 * @return {undefined}
		 */
		subscribe: function(event, fn) {
			$(this).bind(event, fn);
		},

		/**
		 * Unsubscribe a given function from a given event
		 *
		 * @param  {string}   event , event name
		 * @param  {Function} fn    , original caller function
		 * @return {undefined}
		 */
		unsubscribe: function(event, fn) {
			$(this).unbind(event, fn);
		},

		/**
		 * Publishes a given event to all subscribed listeners, calling
		 * their respective functions
		 *
		 * @param  {string} event 		, event name
		 * @param  {object} extraParams	, extra parameters that are sent to all subscribers
		 * @return {undefined}
		 */
		publish: function(event, extraParams) {
			$(this).trigger(event, extraParams);
		},

		/**
		 * Removes all registered subscriptions from the eventbus object
		 *
		 * @return {undefined}
		 */
		reset: function() {
			$(this).unbind();
		}
	};
});
