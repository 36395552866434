define('components/controller/table',['require','jquery','helper','components/controller/event-bus','components/controller/state','components/controller/lang','components/store/devices','components/store/plants','components/store/alarms','text!components/view/table/viewport.html','text!components/view/table/column-header.html','text!components/view/table/column-header-min.html','text!components/view/table/column-header-unsortable.html','text!components/view/table/column-header-unsortable-min.html','text!components/view/table/column-footer.html','text!components/view/table/column-footer-empty.html','text!components/view/table/arrow/left-start.html','text!components/view/table/arrow/down-start.html','text!components/view/table/arrow/down.html','text!components/view/table/arrow/down-end.html','text!components/view/table/arrow/point-down.html','text!components/view/table/arrow/point-down-end.html'],function(require) {
	'use strict';

	var $ 						= require('jquery'),
		__ 						= require('helper'),
		eventbus 				= require('components/controller/event-bus'),
		ctrlState				= require('components/controller/state'),
		ctrlLang 				= require('components/controller/lang'),
		storeDevices 			= require('components/store/devices'),
		storePlants 			= require('components/store/plants'),
		storeAlarms 			= require('components/store/alarms'),
		tableViewportTpl 		= require('text!components/view/table/viewport.html'),
		tableColumnHeader		= require('text!components/view/table/column-header.html'),
		tableColumnHeaderMin	= require('text!components/view/table/column-header-min.html'),
		tableColumnHeaderUnsorted 		=
			require('text!components/view/table/column-header-unsortable.html'),
		tableColumnHeaderUnsortedMin	=
			require('text!components/view/table/column-header-unsortable-min.html'),
		tableColumnFooter 		= require('text!components/view/table/column-footer.html'),
		tableColumnFooterEmpty 	= require('text!components/view/table/column-footer-empty.html'),
		tableArrowLeftStart		= require('text!components/view/table/arrow/left-start.html'),
		tableArrowDownStart		= require('text!components/view/table/arrow/down-start.html'),
		tableArrowDown 			= require('text!components/view/table/arrow/down.html'),
		tableArrowDownEnd		= require('text!components/view/table/arrow/down-end.html'),
		tableArrowPointDown 	= require('text!components/view/table/arrow/point-down.html'),
		tableArrowPointDownEnd	= require('text!components/view/table/arrow/point-down-end.html');

	return {
		length 					: undefined,	// the current amount of table rows
		visibleColumns 			: undefined,	// array with names of the rendered columns
		store 					: undefined,	// the currently connected data store object
		possibleStores 			: {
			'device': storeDevices,
			'plant'	: storePlants,
			'alarm' : storeAlarms
		},
		$tableBody 				: undefined,	// cached access to the <tbody> dom element
		scrollbarWidth 			: undefined,	// contains the calculated browser scrollbar width
		trHeight 				: 31, 			// height of a table row in pixels
		currentRow				: undefined,	// first completely visible row
		preloadOffset 			: -1,			// flag to remember a user scrolldown reload offset
		pendingSortFilter		: false,		// flag for a pending sort / filter update operation


		/**
		 * Initialization method of the table controller module
		 *
		 * @return {object}		, the table controller object
		 */
		init: function() {
			var self = this;

			this.length = 0;
			this.visibleColumns = [];
			this.scrollbarWidth = this.calculateScrollbarWidth();
			this.currentRow = 0;
			this.preloadOffset = -1;

			// add click handler to table buttons with data-action attribute
			this.addClickHandler();

			// window events
			$(window).on('resize', function() {
				if (__.is(ctrlState.getPage()) && ctrlState.getPage() === 'table') {
					self.onWindowResize();
				}
			});

			$(window).on('orientationchange', function() {
				if (__.is(ctrlState.getPage()) && ctrlState.getPage() === 'table') {
					self.onWindowResize();
				}
			});

			// eventbus events
			eventbus.subscribe('login-success', function() {
				self.onLoginSuccess();
			});

			eventbus.subscribe('logout', function() {
				self.onLogout();
			});

			eventbus.subscribe('state-change-level', function(ev, message) {
				self.onStateChangeLevel(message);
			});

			eventbus.subscribe('state-change-language', function() {
				if (ctrlState.getPage() === 'table') {
					self.onStateChangeLanguage();
				}
			});

			eventbus.subscribe('state-change-theme', function() {
				if (ctrlState.getPage() === 'table') {
					self.onStateChangeTheme();
				}
			});

			eventbus.subscribe('store-update', function(ev, message) {
				self.onStoreUpdate(message.offset, message.length);
			});

			eventbus.subscribe('store-update-data-reset', function() {
				self.onStoreUpdateDataReset();
			});

			eventbus.subscribe('store-aggregates-update', function() {
				self.onStoreAggregatesUpdate();
			});

			eventbus.subscribe('store-filters-update', function(ev, message) {
				self.onStoreFiltersUpdate(message.skipDataRefresh);
			});

			eventbus.subscribe('store-columns-update', function(ev, message) {
				self.onStoreColumnsUpdate(message.visibleColumns);
			});

			eventbus.subscribe('table-sort', function(ev, message) {
				self.onTableSort(message.$btn);
			});

			eventbus.subscribe('table-change-aggregate', function(ev, message) {
				self.onTableChangeAggregate(message.$btn);
			});

			eventbus.subscribe('no-portfolio-data', function() {
				self.onNoPortfolioData();
			});

			eventbus.publish('no-aggregation-data', function() {
				self.onNoAggregationData();
			});

			return this;
		},

		/**
		 * Reconfigures the dimensions of the table and the cloned elements if the browser
		 * window was resized
		 *
		 * @event  resize
		 * @return {undefined}
		 */
		onWindowResize: function() {
			var $wrapper = $('div.table-wrapper');

			this.setTableDimensions($wrapper);
			this.updateCloneDimensions($wrapper);
			this.copyTableElementDistances();
		},

		/**
		 * Event handler for a successful user login
		 *
		 * Please note: this is fired if the backend validated the credentials and was able to
		 * generate a authentification token
		 *
		 * @event  login-success
		 * @return {undefined}
		 */
		onLoginSuccess: function() {
			var self = this,
				tpl = tableViewportTpl.slice(0),
				$table,
				$wrapper,
				$row;

			// set the correct store
			this.store = this.possibleStores[ctrlState.getLevel()];

			// cache visible columns
			this.visibleColumns = this.store.getVisibleColumns();

			tpl = tpl
				.replace('{{head}}', this.getTableHeadHtml())
				.replace('{{footer}}', this.getTableFooterHtml());

			this.showSpinner();
			this.render(tpl);

			// cache tableBody access
			this.$tableBody = $('#table-responsive tbody');

			// start building a copy of the table head and a scrollbox around the table
			$table = $('#table-responsive');
			$table.wrap('<div class="table-wrapper"/>');
			$wrapper = $table.parent();

			this.setTableDimensions($wrapper);
			this.setTableAppearance();

			// add the standard event handling to the scroll event
			$wrapper.scroll(function(ev) {
				self.onTableScroll(ev);
			});

			// add another (debounced) scroll handler, that informs the backend of the current row
			// issue #11610: initiate data reloading if the first currently shown row is too old
			$wrapper.scroll(__.debounce(function() {
				$row = self.$tableBody.find('tr[data-index="' + self.currentRow
					+ '"][class^="outdated"]');

				if ($row.length > 0) {
					eventbus.publish('changed-row-offset-to-old-data', self.currentRow);
					self.showSpinner();
				}
				else {
					eventbus.publish('changed-row-offset', self.currentRow);
				}
			}, 250));
		},

		/**
		 * Event handler for a system wide logout call - initiates the reset of the current
		 * table controller module
		 *
		 * @event  logout
		 * @return {undefined}
		 */
		onLogout: function() {
			this.reset();
		},

		/**
		 * Event handler for manually changed level state, clears the current table view
		 * and shows a loading spinner until the next new data block arrives
		 *
		 * @event  state-change-level
		 * @param  {string} level , new level - possible values: plant / device
		 * @return {undefined}
		 */
		onStateChangeLevel: function(level) {
			var $wrapper = $('div.table-wrapper');

			switch (level) {
				case 'plant':
					this.store = storePlants;
					break;
				case 'device':
					this.store = storeDevices;
					break;
				case 'alarm':
					this.store = storeAlarms;
					break;
				default:
					this.store = undefined;
					window.console.warn('undefined level - store could not be initialized');
			}

			this.showSpinner();

			// delete old rows
			$('#table-responsive tbody').html('');
			this.length = 0;

			// create new table head and footer
			this.visibleColumns = this.store.getVisibleColumns();
			$('#table-responsive thead tr').html(this.getTableHeadHtml());
			$('#table-responsive tfoot').html(this.getTableFooterHtml());

			// adjust the shadow copy of the table header
			this.setTableDimensions($wrapper);
			this.setTableAppearance();
			this.copyTableElementDistances();
		},

		/**
		 * Event handler for manually changed language settings, updates the column names
		 * and calculates new distances for left/right scrolling
		 *
		 * @event  state-change-language
		 * @return {undefined}
		 */
		onStateChangeLanguage: function() {
			$('thead tr').html(this.getTableHeadHtml());
			$('tfoot').html(this.getTableFooterHtml());
			this.setTableDimensions($('.table-wrapper'));
			this.copyTableElementDistances();
		},

		/**
		 * Event handler for a changed theme state
		 *
		 * @event  state-change-theme
		 * @return {undefined}
		 */
		onStateChangeTheme: function() {
			var tableHeadHtml,
				$wrapper,
				$tr;

			if (ctrlState.getPage() === 'table' && this.visibleColumns.length > 0) {
				tableHeadHtml = this.getTableHeadHtml();
				$wrapper = $('div.table-wrapper');
				$tr = $('#table-responsive thead tr');

				$tr.html(tableHeadHtml);
				this.setTableDimensions($wrapper);
				this.copyTableElementDistances();
			}
		},

		/**
		 * Event handler for manually changed columns
		 *
		 * @event  store-columns-update
		 * @param  {object} visibleColumns , visible columns config object
		 * @return {undefined}
		 */
		onStoreColumnsUpdate: function(visibleColumns) {
			var $wrapper = $('div.table-wrapper'),
				tableHeadHtml,
				tableFooterHtml;

			this.showSpinner();
			this.visibleColumns = visibleColumns;
			tableHeadHtml = this.getTableHeadHtml();
			tableFooterHtml = this.getTableFooterHtml();
			$('#table-responsive thead tr').html(tableHeadHtml);
			$('#table-responsive tfoot').html(tableFooterHtml);
			this.setTableDimensions($wrapper);
			this.copyTableElementDistances();
			this.setTableAppearance();
		},

		/**
		 * Event handler and workhorse of the table module - table update logic on a store update
		 *
		 * @event  store-update
		 * @param  {int} offset , the offset of the new data in the store cache
		 * @param  {int} length , the length of the updated data list
		 * @return {undefined}
		 */
		onStoreUpdate: function(offset, length) {
			var $wrapper = $('div.table-wrapper'),
				isScreenspaceFilled,
				i;

			for (i = offset; i < offset + length; i++) {
				if (i < this.length) {
					this.updateRow(i);
				}
				else {
					this.addRow(i);
				}
			}

			for (i = 0; i < $('#table-responsive tbody tr').length; i++) {
				if (i < offset || i >= offset + length) {
					this.markOutdatedData(i);
				}
			}

			// if a sort / filter update operation was pending, we have to erase all entries
			// from the table that are not sorted - we also scroll to the top of the table
			if (offset === 0 && this.pendingSortFilter === true) {
				this.pendingSortFilter = false;
				$('#table-responsive tbody tr:gt(' + (length - 1) + ')').remove();
				$('div.table-wrapper').scrollTop(0);
				this.currentRow = 0;
				this.length = length;
				this.preloadOffset = -1;
			}

			// prepare the 2nd table header for scroll events (only once)
			if ($('table#table-responsive thead').length < 2) {
				this.copyTableElements();
			}
			// calculate distances on every device update, so the table head does not brake
			this.copyTableElementDistances();
			this.hideSpinner();

			// bugfix: if there were not enough data elements in the table, no scroll bar shows up
			// -> hence no scroll event can be fired - to circumvent this behaviour more data is
			// loaded immediately
			isScreenspaceFilled = ($wrapper.height() < this.trHeight * this.store.size());
			if (length > 0 && !isScreenspaceFilled) {
				this.tablePreload();
			}

			// reset the preloadOffset, if the offset retrieved is the same -> hence the received
			// event is the answer to the table-preload event fired after the user scrolled down
			if (offset === this.preloadOffset) {
				this.preloadOffset = -1;
			}
		},

		/**
		 * Updates the table footer with new aggregate values and changes the appearance of the footer
		 * according to available aggregate values
		 *
		 * @event  store-aggregates-update
		 * @return {undefined}
		 */
		onStoreAggregatesUpdate: function() {
			this.setTableAppearance();
			$('tfoot').html(this.getTableFooterHtml());
			this.copyTableElementDistances();
		},

		/**
		 * If the current store gets an update to reset its data, this function will reset the
		 * visual representation of the store
		 *
		 * @event  store-update-data-reset
		 * @return {undefined}
		 */
		onStoreUpdateDataReset: function() {
			// remove current table entries
			this.$tableBody.find('tr').remove();

			// remove table footer with aggregation values
			this.setTableAppearance();

			// set internal flags and render user feedback
			this.length = this.store.size();
			this.currentRow = 0;
			this.preloadOffset = -1;
			this.showEndOfTableNotification();
			this.hideSpinner();
		},

		/**
		 * Event handler for the updated filters event, which is emitted by the current store
		 * object
		 *
		 * @event  store-filters-update
		 * @param  {boolean} skipDataRefresh , indicator if the backend skips data refresh
		 * @return {undefined}
		 */
		onStoreFiltersUpdate: function(skipDataRefresh) {
			if (skipDataRefresh !== true) {
				// we only need to remember, that a filter operation was invoked and that a complete
				// table update comes with the next store update
				this.pendingSortFilter = true;
				this.showSpinner();
			}
		},

		/**
		 * Adjusts the positioning of the cloned table header element if the table is scrolled
		 *
		 * @event  scroll
		 * @return {undefined}
		 */
		onTableScroll: function() {
			var $table = $('table#table-responsive'),
				$wrapper = $table.parent(),
				$thead = $table.find('thead').first(),
				$theadClone = $thead.next(),
				$tfooter = $('tfoot.sticky'),
				position = $thead.position(),
				tagName = $theadClone.prop('tagName'),
				firstVisibleRow,
				lastVisibleRow,
				isScrolledDown,
				i = 0,
				clientRectInitialTop = $thead.height() + 54, // unmoved top position of first entry
				rows = this.$tableBody.find('tr'),
				firstRowEl = $(rows[0])[0],
				parentWidth,
				$row;

			// calculate first and last visible rows
			// fallback: user changed columns and the table is re-rendered / or because of missing
			// data empty
			if ((__.is(firstRowEl) && firstRowEl.id === 'end-of-table') || rows.length === 0) {
				firstVisibleRow = 0;
				lastVisibleRow = 0;
			}

			while (!__.is(firstVisibleRow)) {
				$row = $(rows[i]);
				if (i === rows.length) {
					firstVisibleRow = $row.attr('data-index');
				}
				else if ($row[0].getBoundingClientRect().top >= clientRectInitialTop) {
					firstVisibleRow = $row.attr('data-index');
				}
				i++;
			}

			while (!__.is(lastVisibleRow)) {
				$row = $(rows[i]);
				if (i === rows.length) {
					lastVisibleRow = $row.attr('data-index');
				}
				else if ($row.attr('id') === 'end-of-table') {
					lastVisibleRow = (i === 0) ? 0 : $(rows[i-1]).attr('data-index');
				}
				else if ($row[0].getBoundingClientRect().bottom > $wrapper.height()) {
					lastVisibleRow = __.parseInt($row.attr('data-index')) + 10;
					// the +10 is a slightly bigger value to preload new data a little bit earlier
				}
				i++;
			}

			// remember if the user scrolled down
			isScrolledDown = this.currentRow < firstVisibleRow;

			// remember the currently shown first visible row as current row
			this.currentRow = firstVisibleRow;

			// decide if the shadow thead shall be rendered and adjust its position if necessary
			if (__.is(tagName) && tagName.toLowerCase() === 'thead') {
				parentWidth = $wrapper.width() - this.scrollbarWidth;

				// toggle cloned thead element on scrolldown
				if (position.top < 56) {
					$theadClone.show();

					// adjust positioning on x-axis
					if (position.left < 1) {
						$theadClone.css('left', position.left)
							.css('width', parentWidth - position.left);
						$tfooter.css('left', position.left).css('width', parentWidth - position.left);
					}
					else {
						$theadClone.css('left', 0).css('width', parentWidth);
						$tfooter.css('left', 0).css('width', parentWidth);
					}
				}
				else {
					$theadClone.hide();
					$tfooter.css('left', position.left).css('width', parentWidth - position.left);
				}
			}

			// preload more data elements if the user scrolled to the end of the table
			// bugfix: multiple scroll events fired led to many socket emits -> so we remember now
			// the offset with which the preloading is fired and do not preload again until a response
			// with the same offset arrives
			// bugfix2: we now only preload new table data if there has been a serious 'scroll down'
			// event detected (prevents the constant reloading on tiny y-axis differences)
			if (isScrolledDown && this.preloadOffset === -1 && this.store.size() <= lastVisibleRow) {
				this.preloadOffset = this.length;
				this.tablePreload();
			}
		},

		/**
		 * Event handler for the click on a thead column - changes the sort order indicator by
		 * re-coloring the arrows, deletes the table and draws the loading spinner
		 *
		 * @param  {object} $btn , jquery wrapped button element
		 * @return {undefined}
		 */
		onTableSort: function($btn) {
			var columnName = $btn.attr('data-column-name'),
				// select correct buttons for both theads via the data-column-name attribute
				buttons = $('#table-responsive thead button[data-column-name="' + columnName + '"]'),
				ths = $(buttons).closest('th'),
				newSortOrder;

			// remove the :active state from the clicked button to deactivate styling
			$(buttons).blur();

			// trigger the new sort order
			newSortOrder = this.store.changeSortOrder(columnName);

			// remove all other sorting indicators
			$('#table-responsive thead th').removeClass('active')
				.removeClass('asc')
				.removeClass('desc');

			// re-color the arrow indicators of the currently clicked column
			if (__.is(newSortOrder)) {
				$(ths).addClass('active')
					.addClass(newSortOrder.toLowerCase());
			}

			// tell the system that the user updated the sort order of a column
			eventbus.publish('update-sort-order', {
				column: columnName,
				order: newSortOrder
			});

			// remember the pending operation
			this.pendingSortFilter = true;

			// show the loading spinner
			this.showSpinner();
		},

		onTableChangeAggregate: function($btn) {
			var columnName = $btn.parent().attr('data-column-name'),
				buttons = $('.footer--aggregate-button'),
				newAggregationMode;

			// TODO: replace button with loading spinner
			// remove the :active state from the clicked button to deactivate styling
			$(buttons).blur();

			newAggregationMode = this.store.changeAggregationMode(columnName);
			eventbus.publish('update-aggregation-mode', {
				column: columnName,
				mode: newAggregationMode
			});
		},

		/**
		 * Event handler to manage the table viewport if no (additional) portfolio data could
		 * be found
		 *
		 * @event  no-portfolio-data
		 * @return {undefined}
		 */
		onNoPortfolioData: function() {
			// hide visual clues
			this.hideSpinner();

			// remember, that the loading process was finished, but prevent
			this.preloadOffset = -1;

			// render a new info row beneath the current table
			this.showEndOfTableNotification();
		},

		/**
		 * Event handler to manage the table footer (which contains aggregation data) if no
		 * aggregation data could be found
		 *
		 * @event no-aggregation-data
		 * @return {undefined}
		 */
		onNoAggregationData: function() {
			this.setTableAppearance();
		},

		/**
		 * Replaces the current viewport element in the DOM with the given HTML string
		 *
		 * @param  {string} html , (optional) if not specified the viewport is rendered empty
		 * @return {undefined}
		 */
		render: function(html) {
			var $viewport = $('#viewport');

			$viewport.html(__.def(html, ''));
			eventbus.publish('element-render-finish', $viewport);
		},

		/**
		 * Triggers the loading indicator at the bottom of the screen to be visible
		 *
		 * @return {undefined}
		 */
		showSpinner: function() {
			$('#spinner-wrapper').removeClass('hidden');
		},

		/**
		 * Triggers the loading indicator at the bottom of the screen to be hidden
		 *
		 * @return {undefined}
		 */
		hideSpinner: function() {
			$('#spinner-wrapper').addClass('hidden');
		},

		/**
		 * Renders a new row beneath the table to inform the user that no further data could be
		 * retrieved
		 *
		 * @return {undefined}
		 */
		showEndOfTableNotification: function() {
			var $table = $('#table-responsive'),
				$tbody = $table.find('tbody'),
				columns = $table.find('thead > tr > th').length,
				translation = 'INFO_NO_DATA',
				el = '<tr id="end-of-table">'
					+'<td colspan="' + columns + '" data-i18n="' + translation + '">'
					+ ctrlLang._(translation)
					+ '</td>'
					+ '</tr>',
				$wrapper;

			if ($tbody.find('#end-of-table').length === 0) {
				$wrapper = $('div.table-wrapper');
				$tbody.append(el);
				$wrapper.scrollTop($table.height(), 1000);
			}
		},

		/**
		 * Removes the info row beneath the table that no further data could be retrieved
		 *
		 * @return {undefined}
		 */
		hideEndOfTableNotification: function() {
			$('#end-of-table').remove();
		},

		/**
		 * Triggers a loading animation and notifies the system to load new data beginning at
		 * the end of the currently visible list
		 *
		 * @return {undefined}
		 */
		tablePreload: function() {
			this.showSpinner();
			eventbus.publish('table-preload', this.length);
		},

		/**
		 * Creates a HTML representation of the tables <thead> element
		 *
		 * @return {string}		, <thead> HTML representation
		 */
		getTableHeadHtml: function() {
			var self = this,
				store = this.store,
				theme = ctrlState.getTheme(),
				headerTpl = (theme.indexOf('min') > -1)
					? tableColumnHeaderMin : tableColumnHeader,
				headerUnsortableTpl = (theme.indexOf('min') > -1)
					? tableColumnHeaderUnsortedMin : tableColumnHeaderUnsorted,
				fillColor = (theme === 'dark') ? '#cfd0d0' : '#0078be',
				strokeColor = 'none';

			return this.visibleColumns.map(function(col, index) {
				var tmpTpl,
					upperArrowTpl,
					lowerArrowTpl,
					upperDivClass,
					active,
					sortOrder;

				// create template copy, depending on the sortable flag of the current column
				if (store.isColumnSortable(col)) {
					tmpTpl = headerTpl.slice(0);

					// add a coloring class to the up / down arrow depending on the configured
					// sort state of the current column
					switch (store.getSortOrder(col)) {
						case 'ASC':
							active = 'active';
							sortOrder = 'asc';
							break;

						case 'DESC':
							active = 'active';
							sortOrder = 'desc';
							break;

						default:
							active = '';
							sortOrder = '';
					}
				}
				else {
					tmpTpl = headerUnsortableTpl.slice(0);

					// add a hover text with explanation for unsortable columns
					tmpTpl = tmpTpl.replace('{{title}}', ctrlLang._('INFO_UNSORTABLE_COLUMN'));
				}

				// choose svg arrow
				if (index === 0) {
					upperArrowTpl = tableArrowLeftStart.slice(0);
					lowerArrowTpl = tableArrowDownStart.slice(0);
					upperDivClass = 'background-float-first';
				}
				else if (index === self.visibleColumns.length - 1) {
					upperArrowTpl = tableArrowPointDownEnd.slice(0);
					lowerArrowTpl = tableArrowDown.slice(0);
				}
				else {
					upperArrowTpl = tableArrowPointDown.slice(0);
					lowerArrowTpl = tableArrowDownEnd.slice(0);
					upperDivClass = 'background-float';
				}

				return tmpTpl.replace('{{column}}', col)
					.replace('{{label}}', ctrlLang.getColumnName(col))
					.replace('{{active}}', active)
					.replace('{{sortOrder}}', sortOrder)
					.replace('{{upperArrow}}', upperArrowTpl)
					.replace('{{lowerArrow}}', lowerArrowTpl)
					.replace('{{upperDivClass}}', upperDivClass)
					.replace(/{{fillColor}}/g, fillColor)
					.replace(/{{strokeColor}}/g, strokeColor);
			}).reduce(function(former, current) {
				return former + current;
			});
		},

		/**
		 * Creates a HTML representation of the tables <tfoot> element
		 *
		 * @return {string} , HTML representation
		 */
		getTableFooterHtml: function() {
			var self = this,
				tmpTpl = tableColumnFooter.slice(0),
				tmpTplEmpty = tableColumnFooterEmpty.slice(0),
				ret,
				aggregate,
				value,
				typeTranslation;

			// do not return any footer content if no aggregation values are available
			if (!this.store.hasAggregateValue()) {
				return '';
			}

			ret = this.store.getVisibleColumns().reduce(function(all, col) {
				aggregate = self.store.getAggregate(col);
				value = self.store.getAggregateValue(col);

				if (!__.is(value)) {
					return all + tmpTplEmpty.replace('{{column}}', col);
				}
				else {
					typeTranslation = ctrlLang.translate('AGGREGATE_' + aggregate.toUpperCase());
					return all + tmpTpl.replace('{{column}}', col)
						.replace('{{type}}', typeTranslation)
						.replace('{{value}}', __.def(value, ''));
				}
			}, '');

			return '<tr>' + ret + '</tr>';
		},

		/**
		 * Add a new <tr> element to the table element in the DOM
		 *
		 * @param {int} index , the index of an existing table row
		 * @return {undefined}
		 */
		addRow: function(index) {
			// make sure, that no new rows are added beneath the end of the table info row
			this.hideEndOfTableNotification();

			this.length++;

			this.$tableBody.append(
				'<tr data-index="' + index + '">'
				+ this.getColumnsHtml(index)
				+ '</tr>'
			);
		},

		/**
		 * Updates an existing <tr> element of the DOM with new data
		 *
		 * @param  {int} 		index        , the index of an existing table row
		 * @param  {boolean} 	overwriteRow , (optional) create a completely new row instead
		 *                                		of replacing existing values
		 * @return {undefined}
		 */
		updateRow: function(index, overwriteRow) {
			var $row = this.$tableBody.find('tr[data-index="' + index + '"]'),
				data = this.store.getAt(index);

			if (overwriteRow === true) {
				$row.html(this.getColumnsHtml(index));
			}
			else {
				this.visibleColumns.forEach(function(columnName) {
					$row.find('td[data-column="' + columnName + '"]').html(data[columnName]);
				});
			}

			// make the row fully visible again by removing any outdated class
			$row.removeClass();
		},

		/**
		 * Marks a given row as outdated
		 *
		 * @param  {int} row , row number
		 * @return {undefined}
		 */
		markOutdatedData: function(row) {
			var $row = this.$tableBody.find('tr[data-index="' + row + '"]');
			$row.addClass('outdated');
		},

		/**
		 * Creates an html string from a given store item containing all <td> tags of the
		 * configured visible columns
		 *
		 * @param  {int} 	index , the index of the cached store item / the table row
		 * @return {string}       , html table column definition
		 */
		getColumnsHtml: function(index) {
			var data = this.store.getAt(index);

			return this.visibleColumns.map(function(columnName) {
				return '<td data-column="' + columnName+ '">' + data[columnName] + '</td>';
			}).reduce(function(former, current) {
				return former + current;
			});
		},

		/**
		 * Calculates the ideal size of the table wrapper element and applies it
		 *
		 * @param {object} $wrapper , jquery wrapped DOM element
		 * @return {undefined}
		 */
		setTableDimensions: function($wrapper) {
			var $parent = $wrapper.parent(),
				maxWidth = $parent.width(),
				// window height - (header + addonbar + footer + headline)
				maxHeight = $(window).height() - 105; // 194 (+10 to move the bottom scrollbar)

			// if footer was removed add the footer height on top
			if (ctrlState.getState('mobileapp') === true && $('#footer').height() === null) {
				maxHeight += 50;
			}

			$wrapper.css('width', maxWidth).css('height', maxHeight);
		},

		/**
		 * Helper method to 'finish' the appearance of the table after all elements have been set
		 *
		 * @return {undefined}
		 */
		setTableAppearance: function() {
			var $tableFooter = $('#table-responsive tfoot');

			// hide the table footer if no aggregation values are available for the currently visible
			// columns
			if (!this.store.hasAggregateValue()) {
				$tableFooter.hide();
			}
			else {
				$tableFooter.show();
			}
		},

		/**
		 * Creates a deep clone of the current tables header and footer elements and adds them with
		 * a fixed position to the table
		 *
		 * @return {undefined}
		 */
		copyTableElements: function() {
			var $thead = $('#table-responsive thead'),
				$theadClone = $thead.clone(),
				$wrapper = $('#table-responsive').parent();

			$thead.after($theadClone);
			$theadClone.addClass('sticky');
			this.updateCloneDimensions($wrapper);
		},

		/**
		 * Copies all original table header and footer fields' width informations to the cloned
		 * table header and footer fields
		 *
		 * @return {undefined}
		 */
		copyTableElementDistances: function() {
			var theadCols = $('#table-responsive thead:eq(0) th'),
				theadCloneCols = $('#table-responsive thead.sticky th'),
				tfootCloneCols = $('#table-responsive tfoot.sticky td'),
				i;

			// set same column widths as the original table header
			for (i = 0; i < theadCols.length; i++) {
				$(theadCloneCols[i]).css('min-width', $(theadCols[i]).outerWidth());
				$(tfootCloneCols[i]).css('min-width', $(theadCols[i]).outerWidth());
			}
		},

		/**
		 * Updates the css settings 'top' and 'width' of the cloned thead element according to the
		 * positioning and width of the table wrapper element
		 *
		 * @param  {object} $wrapper , jquery wrapped DOM element
		 * @return {undefined}
		 */
		updateCloneDimensions: function($wrapper) {
			var $theadClone = $('thead.sticky'),
				$tfooter = $('tfoot'),
				width = $wrapper.width() - this.scrollbarWidth,
				footerHeight = $('#footer').outerHeight();

			if (!__.isNumber(footerHeight)) {
				footerHeight = 0;
			}

			if ($theadClone.length > 0) {
				$theadClone.css('top', $wrapper.offset().top + 1);
				$theadClone.css('width', width);
			}

			if ($tfooter.length > 0) {
				$tfooter.css('width', width);
				$tfooter.css('bottom', footerHeight + this.scrollbarWidth);
			}
		},

		/**
		 * Tries to automatically calculate the width of the current browsers scrollbar.
		 * If no value can be calculated, the fallback case is 17 pixels.
		 *
		 * @return {int}	, scrollbar width in px
		 */
		calculateScrollbarWidth: function() {
			var $outer = $('<div>')
					.css({visibility: 'hidden', width: 100, overflow: 'scroll'})
					.appendTo('body'),
				widthWithScroll = $('<div>').css({width: '100%'}).appendTo($outer).outerWidth();

    		$outer.remove();

    		return 100 - widthWithScroll;
		},

		/**
		 * Adds a generic click handler to the viewport for buttons with the data-action attribute
		 *
		 * Please note: this handler cannot be bound to the responsive table, because the table
		 * elements can be deleted or replaced depending on the user actions. So it is bound once to
		 * the viewport element.
		 *
		 * @event  'click'
		 * @return {undefined}
		 */
		addClickHandler: function() {
			$('#viewport').on('click', 'button[data-action]', function(ev) {
				var $btn = $(this);

				// prevent the events default behaviour
				ev.preventDefault();

				// send the data-action command to the internal event bus for further processing
				eventbus.publish($btn.attr('data-action'), {
					$btn: $btn,
					event: ev
				});
			});
		},

		/**
		 * Resets the table modules state configuration
		 *
		 * @return {undefined}
		 */
		reset: function() {
			this.length = 0;
			this.visibleColumns.splice(0, this.visibleColumns.length);
			this.store = storeDevices;
			this.$tableBody = undefined;
			this.preloadOffset = -1;
			this.pendingSortFilter = false;

			this.hideSpinner();
			this.hideEndOfTableNotification();
		}
	};
});

