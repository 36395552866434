/**
 * A generic template for specific device/plant/alert stores
 *
 * @return {object}	, store object
 */
define('components/store/generic',['require','jquery','helper','components/controller/event-bus','components/controller/lang','text!components/view/table/column-td-progress-bar.html','text!components/view/table/column-date.html'],function(require) {
	'use strict';

	var $ 					= require('jquery'),
		__ 					= require('helper'),
		eventbus 			= require('components/controller/event-bus'),
		ctrlLang 			= require('components/controller/lang'),
		viewTableColumnTdProgressBar
			= require('text!components/view/table/column-td-progress-bar.html'),
		viewTableColumnDate	= require('text!components/view/table/column-date.html'),
		genericStore;

	genericStore = {
		level 				: undefined,	// internal level reference, e.g. 'plant' or 'device'
		cache 				: undefined,
		pendingSortFilter	: false,
		// the following functions / objects need to be implemented by the inherited object
		columns 			: undefined,
		init 				: undefined,
		onStateChangeLevel	: undefined,
		onNewDeviceData		: undefined,
		onNewPlantData		: undefined,
		onResetData			: undefined,
		// a set of aggregator methods accepted by the skygate backend, derived stores can define a
		// subset of those by adding an array 'ignoreAggregates'
		possibleAggregates	: [ 'max', 'min', 'avg', 'sum' ],

		/**
		 * Event handler for the logout event - resets all internal state values and the cache
		 *
		 * @event  logout
		 * @return {undefined}
		 */
		onLogout: function() {
			// remove cached data
			if (__.isArray(this.cache)) {
				this.cache.splice(0, this.cache.length);
			}

			// reset the standard column settings for filters and sort order
			this.reset();
		},

		/**
		 * Persists new aggregation values in the current store object
		 *
		 * @event  new-plant-aggregation, new-device-aggregation or new-alarm-aggregation
		 * @param  {object} data , parsed skygate XML response
		 * @return {undefined}      [description]
		 */
		onNewStoreAggregation: function(data) {
			var self = this,
				aggregates = {},
				pId,
				pText;

			if (!__.is(data.p)) {
				//eventbus.publish('store-aggregates-update-no-data');
				this.aggregates = aggregates;
				return false;
			}

			if (!__.isArray(data.p)) {
				data.p = [ data.p ];
			}

			data.p.forEach(function(p) {
				pId = p['@attributes'].id;
				pText = p['#text'];

				aggregates[pId] = self.transformAggregateProperty(pId, pText);
			});

			this.aggregates = aggregates;
			eventbus.publish('store-aggregates-update');
		},

		/**
		 * Event handler for the add-filter event - pushes data to the real addFilter method
		 *
		 * @event  add-filter
		 * @param  {string} 	column    		, name of the column
		 * @param  {string} 	operation 		, name of the operation
		 * @param  {int/string} value   		, comparison value
		 * @param  {boolean} 	skipDataRefresh , indicates if the backend should trigger a data refresh
		 * @return {undefined}
		 */
		onAddFilter: function(column, operation, value, skipDataRefresh) {
			this.addFilter(column, operation, value, skipDataRefresh);
		},

		/**
		 * Event handler for the add-filter event - pushes data to the real removeFilter method
		 *
		 * @event  remove-filter
		 * @param  {string} column    	, name of the column
		 * @param  {string} operation 	, name of the operation
		 * @param  {int/string} value   , comparison value
		 * @return {undefined}
		 */
		onRemoveFilter: function(column, operation, value) {
			this.removeFilter(column, operation, value);
		},

		/**
		 * Resets the filter list to the standard set of filters
		 *
		 * Please note: this is invoked by the user, so after resetting the current store, the backend
		 * has to be informed and synced with the current store
		 *
		 * @event  reset-filter
		 * @return {undefined}
		 */
		onResetFilter: function() {
			this.resetFilter();

			// remember the current pending filter state
			this.pendingSortFilter = true;

			eventbus.publish('store-filters-update', {
				action: 'reset'
			});

			eventbus.publish('change-store-settings', {
				level: this.level,
				columns: this.columns
			});
		},

		/**
		 * Event handler for manually changed column visibility
		 *
		 * @event  change-column-settings
		 * @param  {object} data , form data of the navbar controllers columns modal element
		 * @return {undefined}
		 */
		onChangeColumnSettings: function(data) {
			var isOneColumnSelected = false;

			// check if at least one column was selected
			Object.keys(data).some(function(key) {
				isOneColumnSelected = data[key];
				return isOneColumnSelected;
			});
			if (!isOneColumnSelected) {
				eventbus.publish('dialog-warning', 'WARNING_NO_COLUMN_SELECTED');
				return false;
			}

			this.setVisibleColumns(data);
			eventbus.publish('store-columns-update', {
				visibleColumns: this.getVisibleColumns()
			});

			// reset the store
			this.cache = [];
			this.pendingSortFilter = false;
			eventbus.publish('store-update-data-reset');
		},

		/**
		 * A getter for the currently cached data array
		 *
		 * @return {array}	, the current cache
		 */
		get: function() {
			return this.cache;
		},

		/**
		 * A getter for a specific entry of the cached data array
		 *
		 * @param  {int} 	index 	, of the data entry
		 * @return {object}			, the current cache entry at the given index
		 */
		getAt: function(index) {
			return this.cache[index];
		},

		/**
		 * A getter for the current column definitions (including filters and sorting)
		 *
		 * @return {object}		, the current columns definition
		 */
		getColumns: function() {
			return this.columns;
		},

		/**
		 * Returns an array of column names that are currently flagged as 'visible'
		 *
		 * @return {array}		, the columns marked currently as 'visible'
		 */
		getVisibleColumns: function() {
			var columns = this.columns,
				ret = [];

			Object.keys(columns).forEach(function(key) {
				if (columns[key].visible === true ) {
					ret.push(key);
				}
			});

			return ret;
		},

		/**
		 * Returns an array of column names that are currently flagged as 'filterable'
		 *
		 * @return {array}		, the columns marked currently as 'filterable'
		 */
		getFilterableColumns: function() {
			var columns = this.columns,
				ret = [];

			Object.keys(columns).forEach(function(key) {
				if (columns[key].filterable !== false) {
					ret.push(key);
				}
			});

			return ret;
		},

		/**
		 * Takes an array of column names and sets them to 'visible: true' - all other columns
		 * are set to 'visible: false'
		 *
		 * @param 	{array} columns 	, a set of visible columns
		 * @return 	{undefined}
		 */
		setVisibleColumns: function(columns) {
			var self = this;

			Object.keys(columns).forEach(function(key) {
				self.columns[key].visible = columns[key];
			});

			eventbus.publish('change-store-settings', {
				level: this.level,
				columns: this.columns
			});
		},

		/**
		 * Returns an array of all currently active filter objects
		 *
		 * @return {array}		, the currently active filter object
		 */
		getFilter: function() {
			var ret = [],
				columns = this.columns,
				i;

			if (!__.is(this.columns)) {
				return ret;
			}

			Object.keys(this.columns).forEach(function(key) {
				for (i = 0; i < columns[key].filters.length; i++) {
					if (columns[key].filterable !== false) {
						ret.push($.extend({column: key}, columns[key].filters[i]));
					}
				}
			});

			return ret;
		},

		/**
		 * Adds a filter object containing the specified arguments operation and value to the
		 * list of filters for the given column
		 *
		 * @param {string} 		column    		, name of the column for the new filter
		 * @param {string} 		operation 		, filter relation - e.g. greater / not greater / equal
		 * @param {int/sting} 	value     		, comparison value for the relation
		 * @param {boolean} 	skipDataRefresh , indicates if the backend should trigger a data refresh
		 * @return  {undefined}
		 */
		addFilter: function(column, operation, value, skipDataRefresh) {
			// try/catch because of possibly not existing columns object if inherited object
			// did not instantiate it
			try {
				this.columns[column].filters.push({
					operation: operation,
					value: value
				});

				// remember the current pending filter state
				this.pendingSortFilter = true;

				eventbus.publish('store-filters-update', {
					column: column,
					operation: operation,
					value: value,
					action: 'add',
					skipDataRefresh: skipDataRefresh || false
				});

				eventbus.publish('change-store-settings', {
					level: this.level,
					columns: this.columns
				});
			}
			catch (e) {
				eventbus.publish('dialog-warning', 'Could not add filter: ' + e.message);
				window.console.warn('Failed to add filter: ', column, operation, value);
				window.console.error(e);
			}
		},

		/**
		 * Removes the given filter tuple from the set of currently active filters
		 *
		 * Afterwards the system is notified by the event 'store-filters-update'
		 *
		 * @param  {string} column    	, internal name of the column
		 * @param  {string} operation 	, name of the operation
		 * @param  {string/int} value   , the user defined filter value
		 * @return {undefined}
		 */
		removeFilter: function(column, operation, value) {
			var filters = this.columns[column].filters,
				deleteFilter;

			try {
				filters.forEach(function(filter) {
					if (filter.operation === operation && (filter.value === value
						|| filter.value.toString() === value.toString())) {
						deleteFilter = filter;
					}
				});

				// if a filter was found we can remove it and notify the system
				if (__.is(deleteFilter)) {
					filters.splice(filters.indexOf(deleteFilter), 1);

					// remember the current pending filter state
					this.pendingSortFilter = true;

					eventbus.publish('store-filters-update', {
						column: column,
						operation: operation,
						value: value,
						action: 'remove'
					});

					eventbus.publish('change-store-settings', {
						level: this.level,
						columns: this.columns
					});
				}
			}
			catch (e) {
				eventbus.publish('dialog-warning', 'Could not remove filter: ' + e.message);
				window.console.error(e);
			}
		},

		/**
		 * Reset all filters to the standard set of filters
		 *
		 * Please note: this function does not send system events and does not set any 'pending' flag,
		 * because it can be called during the reset event - all this handling should be done during
		 * the user invoked event handler like 'onResetFilter()'
		 *
		 * @return {undefined}
		 */
		resetFilter: function() {
			var self = this;

			Object.keys(this.columns).forEach(function(colName) {
				// remove currently active filters
				self.columns[colName].filters = [];

				// add standard filters to the current filter set if available
				if (__.isArray(self.columns[colName].standardFilters)) {
					self.columns[colName].standardFilters.forEach(function(filter) {
						self.columns[colName].filters.push({
							operation: filter.operation,
							value: filter.value
						});
					});
				}
			});
		},

		/**
		 * Reset the sort order for all columns
		 *
		 * @return {undefined}
		 */
		resetSortOrder: function() {
			var self = this;

			Object.keys(this.columns).forEach(function(colName) {
				self.columns[colName].sortOrder = undefined;
			});
		},

		/**
		 * Returns the current size of the cache array
		 *
		 * @return {int}	, current size of cache
		 */
		size: function() {
			return this.cache.length;
		},

		/**
		 * Cycles the sort order of a given column from undefined to DESC to ASC to undefined
		 * and returns the new sort order
		 *
		 * @param  {string} column , name of the column where to change the sort order
		 * @return {string}        , new sort order
		 */
		changeSortOrder: function(column) {
			var col = this.columns[column],
				newSortOrder;

			col.sortOrder = (col.sortOrder === undefined)
				? 'DESC' : ((col.sortOrder === 'DESC') ? 'ASC' : undefined);

			// issue 11509: reset all active sort orders and only keep the currently set sort order
			newSortOrder = col.sortOrder;
			this.resetSortOrder();
			col.sortOrder = newSortOrder;

			// remember, that an update process is pending now
			this.pendingSortFilter = true;

			// notify the state manager that the settings have changed
			eventbus.publish('change-store-settings', {
				level: this.level,
				columns: this.columns
			});

			return col.sortOrder;
		},

		/**
		 * Returns true, if the given column is not marked as unsortable with 'sortable: false'
		 *
		 * @param  {string}  column , name of the column in question
		 * @return {Boolean}		, true if the column is sortable
		 */
		isColumnSortable: function(column) {
			return (this.columns[column].sortable === false) ? false : true;
		},

		/**
		 * Tries to identify if a given columns object contains valid config options
		 *
		 * @param  {object}  columns , config object containing row-, visibility- and filter-data
		 * @return {Boolean}		 , true if columns config is valid
		 */
		isValidConfig: function(columns) {
			var standardRows = Object.keys(this.columns),
				rows = Object.keys(columns);

			// only test right now: same order of column rows
			return window.JSON.stringify(standardRows) === window.JSON.stringify(rows);
		},

		/**
		 * Returns the current sort state of the given column (ASC / DESC / undefined)
		 *
		 * @param  {string} column 			, name of the column
		 * @return {string}					, the current sort state otherwise undefined
		 */
		getSortOrder: function(column) {
			return this.columns[column].sortOrder;
		},

		/**
		 * Translates a data value into a percentual HTML representation if possible
		 *
		 * @param  {int} value    	, the value itself
		 * @param  {int} maxValue 	, the maximum value that would represent 100%
		 * @return {string}			, HTML representation
		 */
		getProgressBarValue: function(value, maxValue) {
			var progressBarTpl,
				percentValue,
				percentLevel;

			maxValue = maxValue || 100;

			if (__.isNumber(value)) {
				percentValue = window.Math.round((100 / maxValue) * value);
				percentValue = (percentValue < 0) ? 0 : percentValue;
				percentValue = (percentValue > 100) ? 100 : percentValue;

				percentLevel = percentValue - (percentValue % 20);
				percentLevel = 'percent-level-' + percentLevel;

				progressBarTpl = viewTableColumnTdProgressBar.slice(0)
					.replace('{{percentLevel}}', percentLevel)
					.replace('{{percentValue}}', percentValue)
					.replace('{{value}}', value);

				return progressBarTpl;
			}
			else {
				return value;
			}
		},

		/**
		 * Reduces to current value to its shortest representation and and adds a new unit
		 * description if necessary
		 *
		 * @param  {float}  value 		, a value in kilo format
		 * @param  {string} megaUnit 	, a descriptor text for the mega format
		 * @param  {string} gigaUnit 	, a descriptor text for the giga format
		 * @return {string}				, translates '1234567.23450678' (kWh) into '1.23 GWh'
		 */
		getShortestValue: function(value, megaUnit, gigaUnit) {
			if (Math.abs(value) > 1000000) {
				return __.parseFloat(value / 1000000, 2) + ' ' + gigaUnit;
			}
			else if (Math.abs(value) > 1000) {
				return __.parseFloat(value / 1000, 2) + ' ' + megaUnit;
			}
			else {
				return __.parseFloat(value, 2);
			}
		},

		/**
		 * Translates a date value into an HTML representation, that shows the time difference between
		 * now and the given date and a hover effect that shows the given date in local time
		 *
		 * @param  {string} dateStr , as provided by the skygate backend
		 * @return {string}			, HTML representation
		 */
		getDateDiffValue: function(dateStr) {
			var tpl = viewTableColumnDate.slice(0),
				localDateStr = __.utcToLocal(dateStr),
				dateDiff = __.getDateDiff(localDateStr, new Date());

			return tpl.replace('{{dateTitle}}', __.getDateString(localDateStr))
				.replace('{{dateUnit}}', dateDiff.unit)
				.replace('{{dateUnitTranslation}}', ctrlLang._('UNIT_' + dateDiff.unit))
				.replace('{{dateValue}}', dateDiff.value);
		},

		/**
		 * Retrieves the current aggregate setting for the given column name
		 *
		 * @param  {string} columnName , name of the column - e.g. 'ALIAS'
		 * @return {string}            , aggregate mode - e.g. 'sum'
		 */
		getAggregate: function(columnName) {
			return this.columns[columnName].aggregate;
		},

		/**
		 * Returns the aggregation value of a given column name
		 *
		 * @param  {string} columnName , name of the column
		 * @return {number}            , in most cases a parsed float value of the aggregation result
		 */
		getAggregateValue: function(columnName) {
			if (__.is(this.aggregates)) {
				return this.aggregates[columnName];
			}
			return undefined;
		},

		/**
		 * Helper method to check if at least one column of the current store provides an aggretation
		 * field
		 *
		 * @return {Boolean} , true if at least one column with aggregation is found
		 */
		hasAggregate: function() {
			var self = this,
				numberOfAggregates = 0;

			Object.keys(this.columns).forEach(function(columnName) {
				if (self.columns[columnName].visible === true
					&& __.is(self.columns[columnName].aggregate)) {
					numberOfAggregates++;
				}
			});

			return (numberOfAggregates > 0);
		},

		/**
		 * Helper method to check if the current store has configured aggregate columns and at least
		 * one of those columns is currently visible
		 *
		 * @return {Boolean} , true if at least one column is defined as aggregate and visible
		 */
		hasAggregateValue: function() {
			var self = this,
				numberOfAggregateValues;

			if (!__.is(this.aggregates) || Object.keys(this.aggregates).length === 0) {
				return false;
			}

			// count all currently visible columns that share an aggregation entry
			numberOfAggregateValues = Object.keys(this.aggregates).reduce(function(all, current) {
				return all + ((self.columns[current].visible === true) ? 1 : 0);
			}, 0);

			return (numberOfAggregateValues > 0);
		},

		/**
		 * Cycles the aggregation mode of the given column to the next possible value
		 *
		 * @param  {string} columnName  , name of the column
		 * @return {boolean}            , false if column could not be found
		 */
		changeAggregationMode: function(columnName) {
			var mode = this.columns[columnName].aggregate,
				index = this.possibleAggregates.indexOf(mode),
				newIndex;

			if (index === -1) {
				return false;
			}

			newIndex = (index === this.possibleAggregates.length - 1) ? 0 : index + 1;
			this.columns[columnName].aggregate = this.possibleAggregates[newIndex];

			// notify the state manager that the settings have changed
			eventbus.publish('change-store-settings', {
				level: this.level,
				columns: this.columns
			});

			return this.columns[columnName].aggregate;
		},

		dataMap: function(level) {
			var self = this,
				tmpLevel = {},
				pId,
				pText,
				possibleIdValueFields = ['uaid', 'udid', 'ueid', 'upid', 'uvid'];

			// create a new plant template object from the column names
			Object.keys(this.columns).forEach(function(key) {
				tmpLevel[key] = '';
			});

			if (__.is(level.changed)) {
				tmpLevel.changed = this.getDateDiffValue(level.changed['#text']);
			}

			// add possible values for fields that are outside the standard <p> tag in the
			// skyrequest response
			possibleIdValueFields.forEach(function(key) {
				if (__.is(level[key]) && __.is(level[key]['#text'])) {
					tmpLevel[key] = level[key]['#text'];
				}
			});

			// bugfix #11621: only one selected column leads during xml to json transformation to
			// an object instead of an array, which is needed for further processing
			if (!__.isArray(level.p)) {
				level.p = [ level.p ];
			}

			level.p.forEach(function(p) {
				// ignore falsely parsed tags that contain only undefined as value
				if (__.is(p)) {
					pId = p['@attributes'].id;
					pText = p['#text'];

					tmpLevel[pId] = self.transformProperty(pId, pText);
				}
			});

			return tmpLevel;
		},

		/**
		 * Resets the stores internal objects and flags
		 *
		 * @return {undefined}
		 */
		reset: function() {
			this.cache = [];
			this.pendingSortFilter = false;
			this.resetFilter();
			this.resetSortOrder();
		}
	};


	// return a constructor that integrates a config object automatically into the generic
	// store object
	return function(config) {
		return $.extend({}, genericStore, config);
	};
});
