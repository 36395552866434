define('components/controller/dialog',['require','jquery','components/controller/event-bus','components/controller/form','components/controller/lang','helper','text!components/view/modal/standard-2btn.html','text!components/view/modal/footer/1button.html','text!components/view/modal/footer/2buttons.html','text!components/view/modal/footer/3buttons.html','text!components/view/modal/footer/2buttons-nocancel.html','bootstrap-notify'],function(require) {
	'use strict';

	var $ 						= require('jquery'),
		eventbus 				= require('components/controller/event-bus'),
		ctrlForm 				= require('components/controller/form'),
		ctrlLang				= require('components/controller/lang'),
		__ 						= require('helper'),
		modalStd2BtnTpl			= require('text!components/view/modal/standard-2btn.html'),
		modalFooter1Button		= require('text!components/view/modal/footer/1button.html'),
		modalFooter2Buttons		= require('text!components/view/modal/footer/2buttons.html'),
		modalFooter3Buttons 	= require('text!components/view/modal/footer/3buttons.html'),
		modalFooter2ButtonsNocancel =
			require('text!components/view/modal/footer/2buttons-nocancel.html');

	require('bootstrap-notify');

	return {
		/**
		 * Initializes the dialog controller, which mainly controls rendering and behaviour of
		 * modal elements
		 *
		 * @return {object} , the dialog controller itself
		 */
		init: function() {
			var self = this;

			// add bubbled up click handling to the parent modal DOM element
			this.addClickHandler();

			eventbus.subscribe('dialog-warning', function(ev, message) {
				self.onWarning(message);
			});

			eventbus.subscribe('dialog-info', function(ev, message) {
				self.onInfo(message);
			});

			eventbus.subscribe('dialog-modal', function(ev, config) {
				self.onModal(config);
			});

			eventbus.subscribe('dialog-modal-replace', function(ev, config) {
				self.onModalReplace(config);
			});

			eventbus.subscribe('dialog-modal-close', function() {
				self.onModalClose();
			});

			return this;
		},

		/**
		 * Event handler for a user warning, renders a growl style boy in the upper right corner
		 * with the given warning message as content
		 *
		 * @event  dialog-warning
		 * @param  {string} message , the warning message
		 * @return {undefined}
		 */
		onWarning: function(message) {
			message = __.escape(this.translateMessage(message));

			$.notify({
				icon: 'glyphicon glyphicon-warning-sign',
				title: '<strong>' + ctrlLang._('DIALOG_WARNING') + ': </strong>',
				message: message
			}, {
				type: 'danger',
				animate: {
					enter: 'animated fadeInDown',
					exit: 'animated fadeOutUp'
				},
				offset: {
					y:70,
					x:15
				},
				mouse_over: 'pause',
				z_index: 100
			});
		},

		/**
		 * Event handler for a user info, renders a growl style box in the upper right corner
		 * with the given warning message as content
		 *
		 * @event  dialog-info
		 * @param  {string} message , the info message
		 * @return {undefined}
		 */
		onInfo: function(message) {
			message = __.escape(this.translateMessage(message));

			$.notify({
				icon: 'glyphicon glyphicon-info-sign',
				title: '',
				message: message
			}, {
				type: 'info',
				animate: {
					enter: 'animated fadeInDown',
					exit: 'animated fadeOutUp'
				},
				offset: {
					y:70,
					x:15
				},
				mouse_over: 'pause',
				z_index: 100
			});
		},

		/**
		 * Event handler for a user dialog, renders a new modal element with the given configuration,
		 * that should contain the following fields:
		 * title, body, btnAction (optional), btnText (optional)
		 *
		 * @event  dialog-modal
		 * @param  {object} config , the configuration object for the modal element
		 * @return {undefined}
		 */
		onModal: function(config) {
			var $modal = $('#modal'),
				tpl = modalStd2BtnTpl.slice(0),	// create a copy of the template string
				footer;

			config = this.normalizeModalValues(config);

			// integrate footer according to the number of configured buttons
			if (__.isArray(config.buttons) && config.buttons.length === 2) {
				if (config.noCancelButton === true) {
					footer = modalFooter2ButtonsNocancel.slice(0);
				}
				else {
					footer = modalFooter3Buttons.slice(0);
				}

				// pre-process the footer template with settings for multiple buttons, because the
				// data structure differs from the standard 2 button layout
				config.buttons.forEach(function(button, index) {
					Object.keys(button).forEach(function(key) {
						footer = footer.replace('{{' + key + index + '}}', button[key].toString());
					});
				});
			}
			else {
				footer = (__.is(config.btnAction) && __.is(config.btnText))
					? modalFooter2Buttons.slice(0)
					: modalFooter1Button.slice(0);
			}

			tpl = tpl.replace('{{footer}}', footer);

			// modify the template as given by the config
			// please note: this step has to follow _after_ the footer integration for button
			// config replacement
			Object.keys(config).forEach(function(key) {
				tpl = tpl.replace('{{' + key + '}}', config[key].toString());
			});

			// add parsed template to the modal DOM elment
			$modal.html(tpl);

			// replace checkboxes with slider
			$modal.find('input[type="checkbox"]')
				.not('[data-switch-no-init]')
				.bootstrapSwitch({
					size: 'small',
					onColor: 'success',
					onText: ctrlLang._('SELECT_ON').toUpperCase(),
					offText: ctrlLang._('SELECT_OFF').toUpperCase()
				});

			// finally render the new modal and trigger the translation process
			$modal.modal({
				backdrop: 'static',
				keyboard: true,
				show: true
			});
			eventbus.publish('element-render-finish', $modal);
		},

		/**
		 * If the modal leads to another modal, bootstrap cannot hide the current modal and render
		 * it again immediately, so this helper method just replaces the inner html of the current
		 * modal with new settings
		 *
		 * @event  dialog-modal-replace
		 * @param  {object} config , standard modal config object
		 * @return {undefined}
		 */
		onModalReplace: function(config) {
			var $modal = $('#modal'),
				$title = $modal.find('div.modal-header h4'),
				$body = $modal.find('div.modal-body'),
				$footer = $modal.find('div.modal-footer'),
				$content = $modal.find('div.modal-content'),
				tpl = modalFooter2Buttons.slice(0);

			config = this.normalizeModalValues(config);

			Object.keys(config).forEach(function(key) {
				tpl = tpl.replace('{{' + key + '}}', config[key].toString());
			});

			$title.html(config.title || '');
			$body.html(config.body || '');
			$footer.remove();
			$content.append(tpl);
			eventbus.publish('element-render-finish', $modal);
		},

		/**
		 * Resets the form elements the modal contains and closes the modal afterwards
		 *
		 * @event  dialog-modal-close
		 * @return {undefined}
		 */
		onModalClose: function() {
			var $modal = $('#modal'),
				modalForm;

			modalForm = $modal.find('form')[0];
			if (__.is(modalForm)) {
				modalForm.reset();
			}

			$modal.modal('hide');
		},

		/**
		 * Takes the configuration for a new modal element and adds standard values, so that every
		 * parsing function has access to a standard set of fields
		 *
		 * @param  {object} config , given modal elements config object
		 * @return {object}        , normalized modal element config object
		 */
		normalizeModalValues: function(config) {
			// set standard values for the second button
			config.btnColor = config.btnColor || 'primary';
			config.btnIgnoreForm = config.btnIgnoreForm || false;	// continue without form parsing?
			config.btnKeepModal = config.btnKeepModal || false;	// keep modal element open?

			return config;
		},

		/**
		 * Adds a click handler to the modals <button> and <a> elements, that closes the modal
		 * element and publishes the previsously configured event
		 *
		 * Please note: if the body of the modal element contained form elements, those values
		 * are also published as first argument of the published event
		 *
		 * @return {undefined}
		 */
		addClickHandler: function() {
			var $modal = $('#modal'),
				modalForm;

			// click handler for buttons with data-action attribute
			$modal.on('click', 'button[data-action]', function(ev) {
				// extract form values if available
				var data = ctrlForm.extractFormData($modal.find('form')),
					isFormFilled = true;

				// check if every form entry has a value, otherwise let the default event handler
				// do its work (e.g. render form hints with missing fields)
				if (__.is(data) && $(this).attr('data-ignore-form') !== 'true') {
					Object.keys(data).forEach(function(item) {
						if (data[item] === '') {
							isFormFilled = false;
						}
					});
				}

				if (isFormFilled === true) {
					ev.preventDefault();

					// hide the modal element only if a button in the modal footer was pressed
					if ($(this).parent().hasClass('modal-footer')
						&& $(this).attr('data-keep-modal') !== 'true') {
						$modal.modal('hide');
					}

					// publish the configured event (together with form data if available) and
					// reset the form afterwards
					eventbus.publish($(this).attr('data-action'), data);
					modalForm = $modal.find('form')[0];
					if (__.is(modalForm)) {
						modalForm.reset();
					}
				}
			});

			// click handler for links with data-action attribute
			$modal.on('click', 'a[data-action]', function(ev) {
				ev.preventDefault();

				eventbus.publish($(this).attr('data-action'), $(this).data());
			});
		},

		/**
		 * Tries to return a translation for the given message
		 *
		 * @param  {string} message , to be translated
		 * @return {string}			, translated message
		 */
		translateMessage: function(message) {
			// if the message is set, try to translate it first
			if (__.is(message))	{
				message = ctrlLang._(message);
			}
			// if the message is undefined we tell the user that an unknown error occurred
			else if (!__.is(message)) {
				message = ctrlLang._('ERROR_UNKNOWN');
			}

			return message;
		},
	};
});
