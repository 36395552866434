/**
 * Library of static helper methods tailored to the needs of skytron web projects
 *
 * @author Thomas Lack
 */
define('helper',[], function() {
	'use strict';

	return {
		/**
		 * Returns true if the given value is defined
		 *
		 * @param  {object}  value 	, which will be checked for 'undefined'
		 * @return {Boolean}		, true if argument is not undefined
		 */
		is: function(value) {
			return (typeof value !== 'undefined');
		},

		/**
		 * Returns true if the given value is null
		 *
		 * @param  {Object}  value 	, which will be checked for 'null'
		 * @return {Boolean}		, true if argument is null
		 */
		isNull: function(value) {
			return (value === null);
		},

		/**
		 * Returns true if the given object is a function
		 *
		 * @param  {object}  obj 	, which will be checked for 'function'
		 * @return {Boolean}		, true if argument is of type function
		 */
		isFunction: function(obj) {
			return (typeof obj === 'function');
		},

		/**
		 * Returns true if the given object is an array
		 *
		 * @param  {object}  obj 	, which will be checked for 'array' type
		 * @return {Boolean}		, true if argument is of type array
		 */
		isArray: function(obj) {
			if (this.isFunction(window.Array.isArray)) {
				return window.Array.isArray(obj);
			}
			// polyfill
			else {
				return Object.prototype.toString.call(obj) === '[object Array]';
			}
		},

		/**
		 * Returns true if the given object is of type 'object'
		 *
		 * @param  {object}  obj 	, which will be checked for 'object' type
		 * @return {Boolean}		, true if argument is of type object
		 */
		isObject: function(obj) {
			return (typeof obj === 'object');
		},

		/**
		 * Returns true if the given object is of type 'string'
		 *
		 * @param  {object}  str 	, which will be checked for 'string' type
		 * @return {Boolean}		, true if argument is of type string
		 */
		isString: function(str) {
			return (typeof str === 'string');
		},

		/**
		 * Returns true if the given object is of type 'number'
		 *
		 * Please note: also strings that contain only digits are considered a 'number'
		 *
		 * @param  {int/string}	int , which will be checked for 'number' type
		 * @return {Boolean}		, true if argument is of type number
		 */
		isNumber: function(int) {
			return (typeof int === 'number' || /^\d+(.\d+)?$/.test(int));
		},

		/**
		 * Tests, if the browser supports window.localStorage to persist user data in the browser
		 *
		 * @return {boolean} , true if supported - false otherwise
		 */
		isLocalStorageSupported: function() {
			var test = 'test';

			try {
				window.localStorage.setItem(test, test);
				window.localStorage.removeItem(test);
				return true;
			}
			catch (e) {
				return false;
			}
		},

		/**
		 * Tests, if the browser currently supports cookies
		 *
		 * @return {Boolean}	, true if cookies are supported
		 */
		isCookieSupported: function() {
			return (this.is(window.navigator) && window.navigator.cookieEnabled === true);
		},

		/**
		 * Takes a value and a fallback and returns the fallback if the given value is
		 * undefined
		 *
		 * @param  {object} value    	, which will be checked for 'undefined'
		 * @param  {object} fallback 	, which will be returned if value is 'undefined'
		 * @return {object}				, value if value is defined, otherwise the provided fallback
		 */
		def: function(value, fallback) {
			return (typeof value === 'undefined') ? fallback : value;
		},

		/**
		 * Combination function of def() and concat() - checks that the given value
		 * is not 'undefined' and returns a concatenated string or an empty string
		 *
		 * @param  {string} prefix  , first part of concatenation string
		 * @param  {string} value   , second part of concatenation string (checked)
		 * @param  {string} postfix , (optional) third part of concatenation string
		 * @return {string}			, concatenated or empty string
		 */
		opt: function(prefix, value, postfix) {
			if (this.is(value)) {
				return prefix + value + this.def(postfix, '');
			}

			return '';
		},

		/**
		 * Takes all parameters given to this function and returns a concatenated string
		 *
		 * Please note: the first parameter is always the separator
		 *
		 * @param  {string} separator 	, the separator between the concatenated params
		 * @param  {string} varname 	, (multiple possible)
		 * @return {string}				, concatenated string with separator argument as separator
		 */
		concat: function(separator) {
			var args = window.Array.prototype.slice.call(arguments, 1);
			return args.join(separator);
		},

		/**
		 * Tries to create a standard translation '<span data-i18n>' DOM element string
		 * from the given translation key. An optional value will be used as inner html.
		 *
		 * Please note: the language controller will replace the inner HTML, if
		 * translation is triggered
		 *
		 * @param  {string} translationKey 	, a system translation string
		 * @param  {string} value          	, (optional) innerHtml string
		 * @param  {string} tagName        	, (optional) name of opening and closing tag
		 * @return {string}					, a translation string usable by the page
		 */
		wrapI18n: function(translationKey, value, tagName) {
			if (!this.is(translationKey)) {
				return this.def(value, '');
			}
			else {
				return '<' + this.def(tagName, 'span') + ' data-i18n="' + translationKey + '">'
					+ this.def(value, '')
					+ '</' + this.def(tagName, 'span') + '>';
			}
		},

		/**
		 * Creates a standard headline HTML string from the given model
		 *
		 * Please note: the model object should contain something like 'title', 'subtitle' or 'titleI18n'
		 *
		 * @param  {object} model 	, standard model item object as used in the view
		 * @param  {string} size  	, (optional) headline size - e.g. 'h4', standard: 'h2'
		 * @return {string}			, a translation string usable as headline by the page
		 */
		headlineI18n: function(model, size) {
			var html = '';

			// set h2 as standard size if no optional param is set
			size = this.def(size, 'h2');

			if (this.is(model.titleI18n) || this.is(model.title)) {
				html += '<' + size + '>'
					+ this.wrapI18n(model.titleI18n, model.title);

				if (this.is(model.subtitleI18n) || this.is(model.subtitle)) {
					html += '<small>'
						+ this.wrapI18n(model.subtitleI18n, model.subtitle)
						+ '</small>';
				}

				html += '</' + size + '>';
			}

			return html;
		},

		/**
		 * Takes a rpc configuration object from a model definition and flattens it
		 *
		 * Please note: during the flattening process a test or production url is chosen
		 *
		 * @param  {object}  settingsObj 	, rpc config object
		 * @param  {Boolean} isTestMode  	, (optional) indicates which url will be used
		 * @return {object}					, transforms an rpc config object into a flat object
		 */
		flattenRpcConfig: function(settingsObj, isTestMode) {
			var self = this,
				flatObj = {};

			// set standard test mode to false if not defined
			isTestMode = this.def(isTestMode, false);

			// extract rpc config settings
			if (this.is(settingsObj) && this.isObject(settingsObj)) {
				Object.keys(settingsObj).forEach(function(key) {
					if (key === 'url' && self.isObject(settingsObj[key])) {
						flatObj.url = (isTestMode)
							? self.def(settingsObj.url.test, undefined)
							: self.def(settingsObj.url.production, undefined);
					}
					else {
						flatObj[key] = settingsObj[key];
					}
				});
			}

			return flatObj;
		},

		/**
		 * Takes an object and transforms keys like 'a.b.c' into a nested object
		 *
		 * @param  {Object} obj , target object to be unflattened
		 * @return {object}		, an object with deepness >= 1
		 */
		unflat: function(obj) {
			var self = this,
				i;

			if (!this.is(obj) || this.isNull(obj) || !this.isObject(obj)) {
				return;
			}

			Object.keys(obj).forEach(function(key) {
				var tmp = key.split('.'),
					deepness = tmp.length,
					currentNesting = obj;

				if (deepness > 1) {
					// create nested object structure
					for (i = 0; i < deepness; i++) {
						if (!self.is(currentNesting[tmp[i]])) {
							currentNesting[tmp[i]] = {};
						}

						// do not forget to save the original value into the nested object
						if (i === deepness - 1) {
							currentNesting[tmp[i]] = obj[key];
						}
						else {
							currentNesting = currentNesting[tmp[i]];
						}
					}

					// delete original "flat" key/value pair
					delete obj[key];
				}
			});
		},

		/**
		 * A polyfill value to integer parser
		 *
		 * @param  {object} value 	, can be anything from string to double
		 * @return {int}			, a parsed integer value
		 */
		parseInt: function(value) {
			// find the current browsers parseInt() method
			var parser = this.def(window.Number.parseInt, window.parseInt);

			return parser(value);
		},

		/**
		 * A polyfill value to float parser - the amount of decimal places is configured by the
		 * optional decimalPlaces parameter
		 *
		 * @param  {object} value         	, can be anything from string to double
		 * @param  {int} 	decimalPlaces 	, (optional) limits the number of decimal places
		 * @return {float}					, a parsed float value
		 */
		parseFloat: function(value, decimalPlaces) {
			var parser = this.def(window.Number.parseFloat, window.parseFloat);

			if (this.is(decimalPlaces)) {
				// please note: toFixed() returns a string, so we have to double parse the value
				return parser(parser(value).toFixed(decimalPlaces));
			}

			return parser(value);
		},

		/**
		 * Takes an xml object and converts it into a Javascript object
		 *
		 * @param  {object} xml , source object
		 * @return {object}     , target object
		 */
		xmlToJson: function(xml) {
			// Create the return object
			var obj = {},
				i,
				j,
				attribute,
				item,
				nodeName,
				old;

			if (xml.nodeType === 1) { // element
				// do attributes
				if (xml.attributes.length > 0) {
					obj['@attributes'] = {};

					for (j = 0; j < xml.attributes.length; j++) {
						attribute = xml.attributes.item(j);
						obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
					}
				}
			}
			else if (xml.nodeType === 3) { // text
				obj = xml.nodeValue;
			}

			// do children
			if (xml.hasChildNodes()) {
				for (i = 0; i < xml.childNodes.length; i++) {
					item = xml.childNodes.item(i);
					nodeName = item.nodeName;

					if (!this.is(obj[nodeName])) {
						obj[nodeName] = this.xmlToJson(item);
					}
					else {
						if (!this.is(obj[nodeName].push)) {
							old = obj[nodeName];
							obj[nodeName] = [];
							obj[nodeName].push(old);
						}
						obj[nodeName].push(this.xmlToJson(item));
					}
				}
			}
			return obj;
		},


		/**
		 * Returns a function, that, as long as it continues to be invoked, will not
		 * be triggered. The function will be called after it stops being called for
		 * N milliseconds. If `immediate` is passed, trigger the function on the
		 * leading edge, instead of the trailing.
		 *
		 * @param  {function} 	func      	, callback function
		 * @param  {int} 		wait      	, waiting time in ms
		 * @param  {boolean} 	immediate 	, adjust trigger behaviour
		 * @return {function}				, debounced method
		 */
		debounce: function(func, wait, immediate) {
			var timeout;
			return function() {
				var context = this, args = arguments,
					later = function() {
						timeout = null;
						if (!immediate) func.apply(context, args);
					},
					callNow = immediate && !timeout;
				clearTimeout(timeout);
				timeout = setTimeout(later, wait);
				if (callNow) func.apply(context, args);
			};
		},

		/**
		 * Calculate a 32 bit FNV-1a hash
		 * Found here: https://gist.github.com/vaiorabbit/5657561
		 * Ref.: http://isthe.com/chongo/tech/comp/fnv/
		 *
		 * @param {string} 	str 				, the input value
		 * @param {boolean} [asString=false] 	, set to true to return the hash value as
		 *                                    	8-digit hex string instead of an integer
		 * @param {integer} [seed] 				, optionally pass the hash of the previous chunk
		 * @returns {integer | string}			, 32 bit hash value
		 */
		hashFnv32a: function(str, asString, seed) {
		    /*jshint bitwise:false */
		    var i, l,
		        hval = (seed === undefined) ? 0x625d7ab9 : seed;

		    for (i = 0, l = str.length; i < l; i++) {
		        hval ^= str.charCodeAt(i);
		        hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
		    }
		    if ( asString ){
		        // Convert to 8 digit hex string
		        return ('0000000' + (hval >>> 0).toString(16)).substr(-8);
		    }
		    return hval >>> 0;
		},

		/**
		 * Returns an escaped string usable in an html environment
		 *
		 * @param  {string} str , string to be escaped
		 * @return {string}		, escaped argument string
		 */
		escape: function(str) {
			var entityMap = {
			    '&': '&amp;',
			    '<': '&lt;',
			    '>': '&gt;',
			    '"': '&quot;',
			    '\'': '&#39;',
			    '/': '&#x2F;'
			};

			if (this.isString(str)) {
				return str.replace(/[&<>"'/]/g, function(s) {
					return entityMap[s];
				});
			}
			else {
				return str;
			}
		},

		/**
		 * Adds a value to a given array if it does not yet exist in the array
		 * (if an array is given as value, all values of the array are added once)
		 *
		 * @param  {Array} 						arr   	, target array
		 * @param  {string/int/object/array} 	value 	, value to add
		 * @return {Array}								, an array containing the given value once
		 */
		addOnce: function(arr, value) {
			if (this.isArray(value)) {
				value.forEach(function(v) {
					if (arr.indexOf(v) === -1) {
						arr.push(v);
					}
				});
			}
			else {
				if (arr.indexOf(value) === -1) {
					arr.push(value);
				}
			}

			return arr;
		},

		/**
		 * Takes a utc date time string and transforms it into a local date time string
		 *
		 * @param  {string} 	dateStr 	, UTC date - e.g. "2016-09-06 14:55:00"
		 * @param  {boolean} 	showSeconds , (optional) show second values if set to true
		 * @return {string}					, local date
		 */
		utcToLocal: function(dateStr) {
			var date, tmp;

			if (!this.is(dateStr) || this.isNull(dateStr) || dateStr === '') {
				return dateStr;
			}

			tmp = dateStr.split(' ');
			// transform into ISO 8601 format
			date = new Date(tmp[0] + 'T' + tmp[1] + '+00:00');
			// standard date methods now produce a local time format
			return date;
		},

		/**
		 * Transforms a date object into a date string of the format 'YYYY-MM-DD hh:mm'
		 * or 'YYYY-MM-DD hh:mm:ss'
		 *
		 * @param  {Date} 		date        , object containing the date to be converted
		 * @param  {boolean} 	showSeconds , (optional) show seconds if set to true
		 * @return {string}					, a formated date string
		 */
		getDateString: function(date, showSeconds) {
			var month = date.getMonth(),
				day = date.getDate(),
				hour = date.getHours(),
				minute = date.getMinutes(),
				seconds = date.getSeconds(),
				appendZero = function(number) {
					return (number < 10) ? '0' + number : number;
				};

			return date.getFullYear() + '-'
				+ appendZero(month + 1) + '-'
				+ appendZero(day) + ' '
				+ appendZero(hour) + ':'
				+ appendZero(minute)
				+ ((showSeconds === true) ? ':' + appendZero(seconds) : '');
		},

		/**
		 * Calculates a difference between two given dates in the highest possible unit, e.g. in
		 * months
		 *
		 * @param  {Date} older , date object of the older date value
		 * @param  {Date} newer , date object of the newer value
		 * @return {object}       , contains the value and the unit of the calculation
		 */
		getDateDiff: function(older, newer) {
			var self = this,
				seconds,
				ret,
				value,
				calcTable = [
					{
						min: 1,
						max: 60,
						unit: 'SECONDS'
					},
					{
						min: 60,
						max: 3600,		// 60 * 60
						unit: 'MINUTES'
					},
					{
						min: 3600,
						max: 86400,		// 60 * 60 * 24
						unit: 'HOURS'
					},
					{
						min: 86400,
						max: 2592000,	// 60 * 60 * 24 * 30
						unit: 'DAYS'
					},
					{
						min: 2592000,
						max: -1,
						unit: 'MONTHS'
					}
				];

			if (!(newer instanceof Date)|| !(older instanceof Date)) {
				return false;
			}

			seconds = (newer.getTime() - older.getTime()) / 1000;

			calcTable.forEach(function(timespan) {
				if (!self.is(ret) && (timespan.max === -1 || seconds < timespan.max)) {
					value = self.parseFloat((seconds / timespan.min), 0);
					ret = {
						value: value,
						// distinguish between singular and plural value units
						unit: (value !== 1)
							? timespan.unit : timespan.unit.substr(0, timespan.unit.length - 1)
					};
				}
			});

			return ret;
		}
	};
});
