define('components/controller/navbar',['require','jquery','helper','components/controller/event-bus','components/controller/state','components/controller/lang','components/controller/form','components/store/devices','components/store/plants','components/store/alarms','components/atoms/dependant-select','text!components/view/navbar/login.html','text!components/view/navbar/table/standard.html','text!components/view/navbar/table/mobile-app.html','text!components/view/navbar/table/select/menu.html','text!components/view/navbar/table/select/level.html','text!components/view/table/column-select-form.html','text!components/view/table/column-select-item.html','text!components/view/table/column-filter.html','text!components/view/table/column-filter-item.html','text!components/view/table/column-filter-reset.html','text!components/view/settings/application.html','text!components/view/settings/reset.html'],function(require) {
	'use strict';

	var $ 						= require('jquery'),
	 	__ 						= require('helper'),
		eventbus 				= require('components/controller/event-bus'),
		ctrlState 				= require('components/controller/state'),
		ctrlLang 				= require('components/controller/lang'),
		ctrlForm 				= require('components/controller/form'),
		storeDevices 			= require('components/store/devices'),
		storePlants 			= require('components/store/plants'),
		storeAlarms 			= require('components/store/alarms'),
		AtomDependantSelect		= require('components/atoms/dependant-select'),
		navbarLoginTpl 			= require('text!components/view/navbar/login.html'),
		navbarTableStandardTpl 	= require('text!components/view/navbar/table/standard.html'),
		navbarTableMobileAppTpl = require('text!components/view/navbar/table/mobile-app.html'),
		navbarTableSelectMenu	= require('text!components/view/navbar/table/select/menu.html'),
		navbarTableSelectLevel	= require('text!components/view/navbar/table/select/level.html'),
		tableColumnSelectForm	= require('text!components/view/table/column-select-form.html'),
		tableColumnSelectItem	= require('text!components/view/table/column-select-item.html'),
		tableColumnFilter		= require('text!components/view/table/column-filter.html'),
		tableColumnFilterItem 	= require('text!components/view/table/column-filter-item.html'),
		tableColumnFilterReset	= require('text!components/view/table/column-filter-reset.html'),
		settingsApplication		= require('text!components/view/settings/application.html'),
		settingsReset			= require('text!components/view/settings/reset.html'),
		filterSettingSets;

	// create sets of option / availableTo pairs for the second (dependant) <select> element
	// for the filter settings dialog
	filterSettingSets = {
		stringSet: {
			options: [
				{
					value: 'equal',
					translationKey: 'OPTION_EQUAL'
				},
				{
					value: 'not',
					translationKey: 'OPTION_NOT'
				},
				{
					value: 'like',
					translationKey: 'OPTION_LIKE'
				},
				{
					value: 'not_like',
					translationKey: 'OPTION_NOT_LIKE'
				}
			],
			availableTo: ['ALIAS', 'PLANT_ALIAS', 'ALIAS_CUSTOM', 'PUBLIC_NAME', 'PLANT_TZ',
				'SERIAL', 'changed', 'udid', 'upid', 'uvid', 'uaid', 'ueid', 'SYS_STATE_TYPE',
				 'NOTE', 'INVERTER_TYPE', 'DEVICE_ALIAS', 'ERROR_ALIAS', 'DESCRIPTION',
				 'DATE', 'INSERTDATE', 'LASTDATE']
		},
		numberSet: {
			options: [
				{
					value: 'equal',
					translationKey: 'OPTION_EQUAL'
				},
				{
					value: 'not',
					translationKey: 'OPTION_NOT'
				},
				{
					value: 'greater',
					translationKey: 'OPTION_GREATER'
				},
				{
					value: 'greater_equal',
					translationKey: 'OPTION_GREATER_EQUAL'
				},
				{
					value: 'less',
					translationKey: 'OPTION_LESS'
				},
				{
					value: 'less_equal',
					translationKey: 'OPTION_LESS_EQUAL'
				}
			],
			availableTo: ['YIELD_DAY_NORM', 'P_AC_NORM', 'INV_AVAIL', 'P_RAD',
				'YIELD_WEEK_NORM', 'YIELD_DAY', 'YIELD_WEEK', 'P_AC_TOTAL', 'PR_DAY',
				'EL_SUN', 'T_AMB', 'T_MOD', 'P_AC_NOMINAL', 'P_DC_INSTALLED',
				'ALARM_SEV_ACTIVE', 'RISO', 'SYS_SEV', 'SYS_STATE', 'SEVERITY',
				'FORECAST_YIELD_TODAY', 'FORECAST_YIELD_TOMORROW']
		},
		activeSet: {
			options: [
				{
					value: 'equal',
					translationKey: 'OPTION_EQUAL'
				}
			],
			availableTo: ['ACTIVE']
		},
		statusSet: {
			options: [
				{
					value: 'equal',
					translationKey: 'OPTION_EQUAL'
				},
				{
					value: 'not',
					translationKey: 'OPTION_NOT'
				}
			],
			availableTo: ['STATUS']
		}
	};

	return {
		// predefined settings for the different types of pages
		pageConfig: {
			'device': {
				title: 'TITLE_DEVICE',
				store: storeDevices
			},
			'plant' : {
				title: 'TITLE_PLANT',
				store: storePlants
			},
			'alarm' : {
				title: 'TITLE_ALARM',
				store: storeAlarms
			}
		},

		/**
		 * Initializes the navbar module
		 *
		 * @return {Object} , the current navbar module
		 */
		init: function() {
			var self = this;

			// add click handler to navbar buttons with data-action attribute
			this.addClickHandler();

			eventbus.subscribe('logout', function() {
				self.onLogout();
			});

			eventbus.subscribe('render-login-screen', function() {
				self.onRenderLoginScreen();
			});

			eventbus.subscribe('login-success', function() {
				self.onLoginSuccess();
			});

			eventbus.subscribe('navbar-open-filter', function() {
				self.onOpenFilter();
			});

			eventbus.subscribe('navbar-open-columns', function() {
				self.onOpenColumns();
			});

			eventbus.subscribe('navbar-open-settings', function() {
				self.onOpenSettings();
			});

			eventbus.subscribe('navbar-reset-settings', function() {
				self.onResetSettings();
			});

			eventbus.subscribe('navbar-reset-filter', function() {
				self.onResetFilter();
			});

			eventbus.subscribe('navbar-open-logout', function() {
				self.onOpenLogout();
			});

			eventbus.subscribe('store-filters-update', function() {
				self.onStoreFiltersUpdated();
			});

			eventbus.subscribe('state-change-level', function(ev, level) {
				self.onStateChangeLevel(level);
			});

			return this;
		},

		/**
		 * Event handler for the user logout, that switches the navbar from 'table' to 'login'
		 *
		 * @event  logout
		 * @return {undefined}
		 */
		onLogout: function() {
			this.render(navbarLoginTpl);
		},

		/**
		 * Renders the navbar for the login screen
		 *
		 * @event  render-login-screen
		 * @return {undefined}
		 */
		onRenderLoginScreen: function() {
			this.render(navbarLoginTpl);
		},

		/**
		 * Event handler for a successful login, that switches the navbar from 'login' to 'table'
		 *
		 * @event login-success
		 * @return {undefined}
		 */
		onLoginSuccess: function() {
			var tpl = (ctrlState.getMobileApp() === false)
					? navbarTableStandardTpl.slice(0)
					: navbarTableMobileAppTpl.slice(0),
				levelTranslationKey = 'OPTION_' + ctrlState.getLevel().toUpperCase(),
				level = ctrlState.getLevel(),
				deviceActiveClass = (level === 'device') ? 'active' : '',
				plantActiveClass = (level === 'plant') ? 'active' : '',
				alarmActiveClass = (level === 'alarm') ? 'active' : '';

			this.render(
				tpl.replace(/{{levelSelect}}/g, navbarTableSelectLevel)
					.replace('{{menuSelect}}', navbarTableSelectMenu)
					.replace(/{{levelTranslationKey}}/g, levelTranslationKey)
					.replace('{{deviceActiveClass}}', deviceActiveClass)
					.replace('{{plantActiveClass}}', plantActiveClass)
					.replace('{{alarmActiveClass}}', alarmActiveClass)
			);
		},

		/**
		 * Event handler to open a modal element, so the user can specify the active filters
		 * for the current store
		 *
		 * @event  open-filter
		 * @return {undefined}
		 */
		onOpenFilter: function() {
			var level = ctrlState.getLevel(),
				store = this.pageConfig[level].store,
				bodyTpl = tableColumnFilter.slice(0),
				filterableColumns = store.getFilterableColumns(),
				source = [],
				target = filterSettingSets,	// as defined in the header of this file
				dependantSelect,
				$button;

			// create source objects for the first <select> element
			filterableColumns.forEach(function(col) {
				source.push({
					value: col,
					translationKey: ctrlState.getLevel().toUpperCase() + '_PARAM_' + col.toUpperCase()
				});
			});

			// create an atom object for 3 elements with input dependency
			// (combination of 3 <select> or 2 <select> and 1 <input> elements)
			dependantSelect = new AtomDependantSelect({
				firstSelectionId: 'selectColumn',
				firstSelectionTranslationKey: 'LABEL_COLUMN',
				secondSelectionId: 'selectOperation',
				secondSelectionTranslationKey: 'LABEL_RELATION',
				inputId: 'inputValue'
			}, source, target);

			// insert html strings into the template
			bodyTpl = bodyTpl.replace('{{dependantSelect}}', dependantSelect.getHtml())
				.replace('{{filterList}}', this.getFilterListHtml());

			// notify the system to render the modal element
			eventbus.publish('dialog-modal', {
				title: ctrlLang._('MODAL_TITLE_FILTER'),
				body: bodyTpl,
				noCancelButton: true,
				buttons: [
					{
						btnColor: 'primary',
						btnAction: 'dialog-modal-close',
						btnText: ctrlLang._('BUTTON_CLOSE'),
						btnIgnoreForm: true,
						btnKeepModal: false
					},
					{
						btnColor: 'info',
						btnAction: 'navbar-reset-filter',
						btnText: ctrlLang._('BUTTON_RESET'),
						btnIgnoreForm: true, // ignore form fields and trigger button in any case
						btnKeepModal: true
					}
				]
			});

			dependantSelect.addEventHandler($('#modal'));
			// special case : 'Acknowledged + Being Repaired' is not possible for relation 'is not'
			$('#selectOperation').on('change', this.onFilterSelectOperationChange);


			// remove standard behavior of the add-filter button to let the <select> box values live
			$button = $('#modal button[data-action="add-filter"]');
			$button.unbind('click');
			$button.on('click', this.onAddFilterClick);
		},

		/**
		 * Event handler for the 'change' event of the second <select> element (operation) of the
		 * filter settings modal
		 *
		 * Please note: this event handler is needed for special cases of the filter settings
		 * (issue #11651)
		 *
		 * @event  change
		 * @return {undefined}
		 */
		onFilterSelectOperationChange: function() {
			var $option;

			if ($('#selectColumn').val() === 'STATUS') {
				$option = $('#inputValue option[value="3 AND 5"]');
				if ($(this).val() === 'not') {
					$option.addClass('hidden');
					// automatically select the first option in case the user switches back from
					// 'is not' relation to 'equals' and selected this option before
					if ($('#inputValue option:selected').val() === '3 AND 5') {
						$('#inputValue option').first().prop('selected', true);
					}
				}
				else {
					$option.removeClass('hidden');
				}
			}
		},

		/**
		 * Event handler for the button 'Add Filter' of the filter settings modal
		 *
		 * @event  click
		 * @param  {object} ev , Event object
		 * @return {undefined}
		 */
		onAddFilterClick: function(ev) {
			var $input = $('#inputValue'),
				$form = $('#modal form'),
				data = ctrlForm.extractFormData($form);

			if (data.inputValue !== '') {
				ev.preventDefault();
				ev.stopPropagation();
				ev.stopImmediatePropagation();

				// special case: combined values, e.g. on error state values (ticket #11651)
				if (data.selectColumn === 'STATUS' && data.inputValue.indexOf(' AND ') > -1) {
					// Please note: if the selected operation is not 'equal' it is assumed that
					// the operation is 'not', also it is assumed that only 4 states exist
					// (0=new, 3=acknowledged, 5=being repaired, 7=repaired)
					switch (data.inputValue) {
						case '0 AND 3':
							data.selectOperation = (data.selectOperation === 'equal')
								? 'less_equal' : 'greater';
							data.inputValue = 3;
							break;
						case '3 AND 5':
							data.selectOperation = 'greater_equal';
							data.inputValue = 3;
							data.skipDataRefresh = true;
							eventbus.publish('add-filter', data);
							data.selectOperation = 'less_equal';
							data.inputValue = 5;
							delete data.skipDataRefresh;
							break;
						case '5 AND 7':
							data.selectOperation = (data.selectOperation === 'equal')
								? 'greater_equal' : 'less';
							data.inputValue = 5;
							break;
					}
				}

				eventbus.publish('add-filter', data);

				// only delete user input on <input> elements, do not nullify <select> options
				if ($input.prop('tagName') === 'INPUT') {
					$input.val('');
				}
			}
		},

		/**
		 * Helper function to update a _modal_ element that shows a list of all active filters
		 *
		 * Please note: this handler is only in the navbar controller, because the modal element
		 * creation is also invoked by this navbar controller
		 *
		 * @event  store-filters-updated
		 * @return {undefined}
		 */
		onStoreFiltersUpdated: function() {
			var $filterList = $('#modal ul.filter-list'),
				filterList;

			if ($filterList.length > 0) {
				filterList = this.getFilterListHtml();
				$filterList.html(filterList);
			}
		},

		/**
		 * Event handler, that creates a modal element to select which columns of the current
		 * store shall be rendered
		 *
		 * @event  open-columns
		 * @return {undefined}
		 */
		onOpenColumns: function() {
			var level = ctrlState.getLevel(),
				store = this.pageConfig[level].store,
				title = this.pageConfig[level].title,
				body = '';

			// prepare modal body
			Object.keys(store.getColumns()).forEach(function(key) {
				var tpl = tableColumnSelectItem.slice(0),
					checked = store.getColumns()[key].visible ? 'checked' : '';

				body += tpl.replace(/{{name}}/g, key)
					.replace('{{label}}', ctrlLang.getColumnName(key))
					.replace('{{checked}}', checked);
			});
			body = tableColumnSelectForm.replace('{{body}}', body);

			// tell the dialog controller to render the generated modal
			eventbus.publish('dialog-modal', {
				title: ctrlLang._('MODAL_TITLE_COLUMNS').replace('{{level}}', ctrlLang._(title)),
				body: body,
				btnAction: 'change-column-settings',
				btnText: ctrlLang._('BUTTON_APPLY')
			});
		},

		/**
		 * Event handler, that creates a modal element to change the application settings
		 *
		 * @event  open-settings
		 * @return {undefined}
		 */
		onOpenSettings: function() {
			var body = settingsApplication.slice(0),
				language = ctrlState.getLanguage(),
				theme = ctrlState.getTheme();

			// set current state as standard values
			body = body.replace('{{languageEnSelected}}', (language === 'en') ? 'selected' : '')
				.replace('{{languageDeSelected}}', (language === 'de') ? 'selected' : '')
				.replace('{{themeLightMinSelected}}', (theme === 'light-min') ? 'selected' : '')
				.replace('{{themeDarkMinSelected}}', (theme === 'dark-min') ? 'selected' : '')
				.replace('{{themeLightSelected}}', (theme === 'light') ? 'selected' : '')
				.replace('{{themeDarkSelected}}', (theme === 'dark') ? 'selected' : '');

			eventbus.publish('dialog-modal', {
				title: ctrlLang._('MODAL_APP_SETTINGS_TITLE'),
				body: body,
				buttons: [
					{
						btnColor: 'primary',
						btnAction: 'change-application-settings',
						btnText: ctrlLang._('BUTTON_APPLY'),
						btnKeepModal: 'false'
					},
					{
						btnColor: 'info',
						btnAction: 'navbar-reset-settings',
						btnText: ctrlLang._('BUTTON_RESET'),
						btnKeepModal: 'true' // keep the modal window open after button press
					}
				]
			});
		},

		/**
		 * Creates a reset settings confirmation dialog
		 *
		 * @event  navbar-reset-settings
		 * @return {undefined}
		 */
		onResetSettings: function() {
			eventbus.publish('dialog-modal-replace', {
				title: ctrlLang._('MODAL_RESET_SETTINGS_TITLE'),
				body: settingsReset.slice(0),
				btnAction: 'reset-application-settings',
				btnText: ctrlLang._('BUTTON_RESET'),
				btnColor: 'info'
			});
		},

		/**
		 * Creates a reset filter confirmation dialog
		 *
		 * @event  navbar-reset-filter
		 * @return {undefined}
		 */
		onResetFilter: function() {
			eventbus.publish('dialog-modal-replace', {
				title: ctrlLang._('MODAL_RESET_FILTER_TITLE'),
				body: tableColumnFilterReset.slice(0),
				btnAction: 'reset-filter',
				btnText: ctrlLang._('BUTTON_RESET'),
				btnColor: 'info'
			});
		},

		/**
		 * Event handler, that creates a logout confirmation dialog
		 *
		 * Please note: the follow-up event is 'user-logout', which will initiate a manually invoked
		 * auth token release by the communication controller and afterwards call the standard
		 * 'logout' event by the com controller
		 *
		 * @event  open-logout
		 * @return {undefined}
		 */
		onOpenLogout: function() {
			eventbus.publish('dialog-modal', {
				title: ctrlLang._('MODAL_TITLE_LOGOUT'),
				body: ctrlLang._('MODAL_BODY_LOGOUT'),
				btnAction: 'user-logout',
				btnText: ctrlLang._('BUTTON_LOGOUT')
			});
		},

		/**
		 * On a change of the level state all navbar items which could possibly show the current
		 * level are retrieved and their translation keys and text elements are replaced with the
		 * new levels values
		 *
		 * @event  state-change-level
		 * @param  {string} level , the new level state
		 * @return {undefined}
		 */
		onStateChangeLevel: function(level) {
			var $el = $('nav.navbar span[data-purpose="current-level-name"]'),
				translationKey = 'OPTION_' + level.toUpperCase();

			$el.attr('data-i18n', translationKey);
			$el.text(ctrlLang._(translationKey));

			$('ul[data-purpose="select-level"] > li.nav--link-level').removeClass('active');
			$('ul[data-purpose="select-level"] > li.nav--link-level > a[data-level="' + level + '"]')
				.parent()
				.addClass('active');
		},

		/**
		 * Applies the currents store filters to the filter list item template and returns
		 * the resulting html
		 *
		 * @return {string}		, HTML representation
		 */
		getFilterListHtml: function() {
			var level = ctrlState.getLevel(),
				store = this.pageConfig[level].store,
				alarmStatusMapping = {
					0: 'ALARM_STATUS_NEW',
					3: 'ALARM_STATUS_NOTED',
					5: 'ALARM_STATUS_REPAIRING',
					7: 'ALARM_STATUS_REPAIRED'
				},
				html = '';

			store.getFilter().forEach(function(filter) {
				var tpl = tableColumnFilterItem.slice(0),
					columnTranslation = ctrlLang.getColumnName(filter.column),
					operationTranslation = ctrlLang._('OPTION_' + filter.operation.toUpperCase()),
					valueTranslation,
					shortened = false;

				// check if the complete filter string is too long and needs to be shortened
				while (columnTranslation.length + operationTranslation.length > 30) {
					columnTranslation = columnTranslation.substring(0, columnTranslation.length - 1);
					shortened = true;
				}
				if (shortened === true) {
					columnTranslation += '...';
				}

				// replace value with the translation and check if printed value string would be too long
				if (filter.value === 'true' || filter.value === 'false') {
					valueTranslation = ctrlLang._('OPTION_'
						+ ((filter.value === 'true') ? 'ACTIVE' : 'NOT_ACTIVE'));
				}
				else if (filter.column === 'STATUS') {
					valueTranslation = ctrlLang._(alarmStatusMapping[filter.value]);
				}
				else {
					valueTranslation = filter.value;
				}
				shortened = false;

				while (columnTranslation.length + operationTranslation.length
					+ valueTranslation.length > 41) {
					valueTranslation = valueTranslation.substring(0, valueTranslation.length - 1);
					shortened = true;
				}
				if (shortened === true) {
					valueTranslation += '...';
				}

				tpl = tpl.replace('{{columnTranslation}}', columnTranslation)
					.replace('{{operationTranslation}}', operationTranslation)
					.replace('{{valueTranslation}}', valueTranslation)
					.replace('{{column}}', filter.column)
					.replace('{{operation}}', filter.operation)
					.replace('{{value}}', __.escape(filter.value));

				html += tpl;
			});

			return html;
		},

		/**
		 * Adds a click handler to the navbar element, so that every button element with a
		 * data-action attribute triggers an event with the name of the attribute
		 *
		 * @event  click
		 * @return {undefined}
		 */
		addClickHandler: function() {
			// click handler for all buttons and links with data-action attribute
			$('nav.navbar').on('click', '[data-action]', function(ev) {
				var $btn = $(this);

				// prevent the events default behaviour
				ev.preventDefault();

				// send the data-action command to the internal event bus for further processing
				eventbus.publish($btn.attr('data-action'), {
					$btn: $btn,
					event: ev
				});
			});

			// click handler for all dropdown buttons with data-level attribute
			// please note: these links will lead to a change of the currently displayed level
			$('nav.navbar').on('click', 'ul[data-purpose="select-level"] a[data-level]', function(ev) {
				ev.preventDefault();
				eventbus.publish('change-application-settings', {
					selectLevel: $(this).attr('data-level')
				});
			});
		},

		/**
		 * Renders the given template into the DOMs navbar element and notifies the system for
		 * further processing, e.g. translating language fields
		 *
		 * @param  {string} tpl , view template
		 * @return {undefined}
		 */
		render: function(tpl) {
			var $navbar = $('nav.navbar');

			$navbar.html(tpl);
			eventbus.publish('element-render-finish', $navbar);
		}
	};
});

