define('components/controller/form',['require','jquery'],function(require) {
	'use strict';

	var $ = require('jquery');

	return {
		init: function() {
			this.addValHooks();

			return this;
		},

		addValHooks: function() {
			$.valHooks.checkbox = {
				set: function(el, checked) {
					if (checked === true
						|| checked === 1
						|| checked === '1'
						|| (typeof checked === 'string' && checked.toLowerCase() === 'true')) {
						$(el).prop('checked', true);
					}
					else {
						$(el).prop('checked', false);
					}
				},

				get: function(el) {
					return $(el).is(':checked');
				}
			};
		},

		extractFormData: function($el) {
			var data = {};

			if (!$el.is('form')) {
				return undefined;
			}

			$el.find('input').each(function() {
				data[$(this).attr('id').replace('col-', '')] = $(this).val();
			});

			$el.find('select').each(function() {
				data[$(this).attr('id')] = $(this).val();
			});

			return data;
		}
	};
});
