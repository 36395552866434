define('components/store/alarms',['require','components/controller/event-bus','components/controller/state','components/controller/lang','components/store/generic','helper'],function(require) {
	'use strict';

	var eventbus 		= require('components/controller/event-bus'),
		ctrlState		= require('components/controller/state'),
		ctrlLang 		= require('components/controller/lang'),
		GenericStore	= require('components/store/generic'),
		__				= require('helper'),
		store;

	// create new alarms store from the generic store
	store = new GenericStore({
		level: 'alarm',
		// possible column entry values:
		// visible (boolean), sortOrder (string), filters (array), standardFilters (array),
		// aggregate (string), ignoreAggregates (array)
		columns: {
			// ---- start of initially visible columns
			ACTIVE: {
				visible: true,
				sortOrder: undefined,
				filters: []
			},
			SEVERITY: {
				// values 1-10 in different colors
				visible: true,
				sortOrder: 'DESC',
				filters: []
			},
			STATUS: {
				visible: true,
				sortOrder: undefined,
				filters: []
			},
			PLANT_ALIAS: {
				visible: true,
				sortOrder: undefined,
				filters: []
			},
			DEVICE_ALIAS: {
				visible: true,
				sortOrder: undefined,
				filters: []
			},
			ERROR_ALIAS: {
				visible: true,
				sortOrder: undefined,
				filters: []
			},
			DESCRIPTION: {
				visible: true,
				sortOrder: undefined,
				filters: []
			},
			// ---- end of initially visible columns
			// ---- start of additional measurement values
			DATE: {
				visible: false,
				sortOrder: undefined,
				filters: []
			},
			INSERTDATE: {
				visible: false,
				sortOrder: undefined,
				filters: []
			},
			LASTDATE: {
				visible: false,
				sortOrder: undefined,
				filters: []
			},
			// ---- end of additional measurement values
			// ---- start of configuration parameters
			changed: {
				visible: false,
				sortOrder: undefined,
				filters: [],
				// sortable: true,	// standard value if not set: true
				filterable: false
			},
			upid: {
				visible: false,
				sortOrder: undefined,
				filters: []
			},
			udid: {
				visible: false,
				sortOrder: undefined,
				filters: []
			},
			uvid: {
				visible: false,
				sortOrder: undefined,
				filters: []
			},
			uaid: {
				visible: false,
				sortOrder: undefined,
				filters: []
			},
			ueid: {
				visible: false,
				sortOrder: undefined,
				filters: []
			}
			// ---- end of configuration parameters
		},

		statusMap: {
			0: 'ALARM_STATUS_NEW',
			1: 'ALARM_STATUS_UNKNOWN',
			2: 'ALARM_STATUS_UNKNOWN',
			3: 'ALARM_STATUS_NOTED',
			4: 'ALARM_STATUS_UNKNOWN',
			5: 'ALARM_STATUS_REPAIRING',
			6: 'ALARM_STATUS_UNKNOWN',
			7: 'ALARM_STATUS_REPAIRED',
			8: 'ALARM_STATUS_UNKNOWN',
			9: 'ALARM_STATUS_UNKNOWN',
			10: 'ALARM_STATUS_DISMISSED'
		},

		init: function() {
			var self = this;

			this.cache = [];

			eventbus.subscribe('logout', function() {
				self.onLogout();
			});

			eventbus.subscribe('new-alarm-data', function(ev, message) {
				self.onNewAlarmData(message.offset, message.data);
			});

			eventbus.subscribe('reset-data', function() {
				if (ctrlState.getLevel() === 'alarm') {
					self.onResetData();
				}
			});

			eventbus.subscribe('add-filter', function(ev, message) {
				if (ctrlState.getLevel() === 'alarm') {
					self.onAddFilter(message.selectColumn, message.selectOperation, message.inputValue,
						message.skipDataRefresh);
				}
			});

			eventbus.subscribe('remove-filter', function(ev, message) {
				if (ctrlState.getLevel() === 'alarm') {
					self.onRemoveFilter(message.column, message.operation, message.value);
				}
			});

			eventbus.subscribe('reset-filter', function() {
				if (ctrlState.getLevel() === 'alarm') {
					self.onResetFilter();
				}
			});

			eventbus.subscribe('change-column-settings', function(ev, data) {
				if (ctrlState.getLevel() === 'alarm') {
					self.onChangeColumnSettings(data);
				}
			});

			eventbus.subscribe('state-change-level', function(ev, message) {
				self.onStateChangeLevel(message);
			});

			eventbus.subscribe('restore-alarms-store', function(ev, columns) {
				self.onRestoreAlarmsStore(columns);
			});

			return this;
		},

		onNewAlarmData: function(offset, data) {
			var i;

			// only process the new data if the current level state has not changed
			// please note: there could still be a new data set on the line while the
			// user changed the level of the data
			if (ctrlState.getLevel() !== 'alarm') {
				return false;
			}

			if (offset === 0 && this.pendingSortFilter === true) {
				this.cache = [];
				this.pendingSortFilter = false;
			}

			// bugfix issue 11511: the xml parser translates single results into an object and
			// multiple results into an array of objects
			if (__.isArray(data)) {
				for (i = 0; i < data.length; i++) {
					this.cache[offset + i] = this.dataMap(data[i]);
				}
			}
			else {
				this.cache[offset] = this.dataMap(data);
			}

			eventbus.publish('store-update', {
				offset: offset,
				length: data.length || 1	// fallback if single object instead of array
			});
		},

		onResetData: function() {
			this.cache = [];
			this.pendingSortFilter = false;

			eventbus.publish('store-update-data-reset');
		},

		onStateChangeLevel: function(level) {
			// please note: we do not want a complete reset, because we want to keep the
			// sort order and filter settings synced with the backend, so we can re-build the
			// ui if the user switches back to the this level / store
			if (level !== 'alarm') {
				this.cache = [];
				this.pendingSortFilter = false;
			}
		},

		onRestoreAlarmsStore: function(columns) {
			if (this.isValidConfig(columns)) {
				this.columns = columns;
			}
			else if (__.is(window.console)) {
				window.console.warn('Tried to restore invalid alarm config - resetting');
				eventbus.publish('invalid-store-config', 'alarm');
			}
		},

		transformProperty: function(pId, pText) {
			switch (pId) {
				case 'STATUS':
					return '<span data-i18n="' + this.statusMap[pText]+ '">'
						+ ctrlLang._(this.statusMap[pText]) + '</span>';
				case 'ACTIVE':
					return (pText === 't')
						? '<span class="glyphicon glyphicon-alert red"></span>'
						: '<span class="glyphicon glyphicon-minus opacity-20"></span>';
				case 'SEVERITY':
					return '<span class="badge severity-' + pText + '">'+ pText + '</span>';
				case 'DATE':
					return __.getDateString(__.utcToLocal(pText));
				case 'INSERTDATE':
					return __.getDateString(__.utcToLocal(pText));
				case 'LASTDATE':
					return this.getDateDiffValue(pText);
				default:
					return pText;
			}
		}
	});

	// expose the store object as module
	return store;
});
