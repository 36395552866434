define('components/atoms/dependant-select',['require','jquery','helper','components/controller/event-bus','text!components/view/atoms/dependant-select.html','text!components/view/atoms/input/select-active.html','text!components/view/atoms/input/select-status.html','text!components/view/atoms/input/string.html','text!components/view/atoms/input/number.html'],function(require) {
	'use strict';

	var $ 							= require('jquery'),
		__ 							= require('helper'),
		eventbus 					= require('components/controller/event-bus'),
		viewAtomDependantSelect 	= require('text!components/view/atoms/dependant-select.html'),
		viewAtomInputSelectActive 	= require('text!components/view/atoms/input/select-active.html'),
		viewAtomInputSelectStatus 	= require('text!components/view/atoms/input/select-status.html'),
		viewAtomInputString 		= require('text!components/view/atoms/input/string.html'),
		viewAtomInputNumber 		= require('text!components/view/atoms/input/number.html'),
		standardConfig = {
			firstSelectionId: 'selection-1',
			firstSelectionTranslationKey: 'UNKNOWN',
			secondSelectionId: 'selection-2',
			secondSelectionTranslationKey: 'UNKNOWN',
			inputId: 'UNKNOWN'
		};

	/**
	 * Template object for 'dependant select elements', used for an atomic screen element containing
	 * 3 <select> or 2 <select> and 1 <input> element.
	 *
	 * Please note: the behaviour of the the first <select> element can be monitored via the
	 * 'addEventHandler' function, which listens to a 'change' event and re-sets the next two elements
	 * according to the selected user option.
	 *
	 * @param {object} 	config , may contain the following entries:
	 *                         		firstSelectionId, firstSelectionTranslationKey
	 *                           	secondSelectionId, secondSelectionTranslationKey,
	 *                            	inputId
	 * @param {array} 	source , an array containing column objects with a value and a translationKey
	 * @param {object} 	target , contains predefined sets of possible options for allowed column names
	 *                         		e.g. as seen in the header of components/controller/navbar.js
	 * @return {undefined}
	 */
	function DependantSelect(config, source, target) {
		config = $.extend({}, standardConfig, config);

		// return new object even if called without the new operator
		if (this instanceof DependantSelect === false) {
			return new DependantSelect(config, source, target);
		}

		this.source = source;
		this.target = target;
		this.config = config;
	}
	DependantSelect.prototype.constructor = DependantSelect;

	/**
	 * Returns a config value for the given key from the internal config object
	 *
	 * @param  {string} key , that contains the search value
	 * @return {object}		, config value
	 */
	DependantSelect.prototype.getConfig = function(key) {
		return this.config[key];
	};

	/**
	 * Saves a new source array to the instance of DependantSelect
	 *
	 * @param {array} source , an array containing column objects with a value and a translationKey
	 * @return {undefined}
	 */
	DependantSelect.prototype.setSource = function(source) {
		this.source = source;
	};

	/**
	 * Retrieves the source object from the instance of DependantSelect
	 *
	 * @return {array}		, source
	 */
	DependantSelect.prototype.getSource = function() {
		return this.source;
	};

	/**
	 * Saves a new target config object to the instance of DependantSelect
	 *
	 * @param {object} target , contains predefined sets of possible options for allowed column names
	 *                         		e.g. as seen in the header of components/controller/navbar.js
	 * @return {undefined}
	 */
	DependantSelect.prototype.setTarget = function(target) {
		this.target = target;
	};

	/**
	 * Retrieves the target config object from the instance of DependantSelect
	 *
	 * @return {object}		, target config object
	 */
	DependantSelect.prototype.getTarget = function() {
		return this.target;
	};

	/**
	 * Transforms an array containing sets of values and translationKeys into an HTML string
	 * representation using <option> elements
	 *
	 * @param  {array} arr 	, an array containing column objects with a value and a translationKey
	 * @return {string}		, HTML representation
	 */
	DependantSelect.prototype.getSelectionOptions = function(arr) {
		var html = '';

		arr.forEach(function(a) {
			html += '<option value="' + a.value + '" data-i18n="' + a.translationKey + '"></option>';
		});

		return html;
	};

	/**
	 * Creates an HTML string containing <option> elements to be inserted into a <select> element
	 *
	 * Please note: the result will only contain <option> elements of a target-set to which the
	 * given first option value belongs
	 *
	 * @param  {string} firstOptionValue 	, the currently selected option of the first <select>
	 *                                    	  element
	 * @return {string}						, html representation of the second <select> element
	 */
	DependantSelect.prototype.getSecondSelectionOptions = function(firstOptionValue) {
		var html = '',
			mappingSetName;

		// if no option was provided the first option of the source is the standard value
		firstOptionValue = firstOptionValue || this.getSource()[0].value;

		mappingSetName = this.getMappingSetName(firstOptionValue);

		if (!__.is(mappingSetName)) {
			return [];
		}
		else return this.getSelectionOptions(this.target[mappingSetName].options);

		return html;
	};

	/**
	 * Identifies the name of the set that the given option value belongs to / is allowed for,
	 * e.g. 'ALIAS' would be mapped to 'stringSet'
	 *
	 * @param  {string} optionValue , name of the option
	 * @return {string}             , name of the set as configured in the 'target' object
	 */
	DependantSelect.prototype.getMappingSetName = function(optionValue) {
		var self = this,
			name;

		Object.keys(this.target).forEach(function(set) {
			if (!__.is(name) && self.target[set].availableTo.indexOf(optionValue) > -1) {
				name = set;
			}
		});

		return name;
	};

	/**
	 * Maps a given set-name from the configured 'target' object to an html template and returns it
	 *
	 * @param  {string} mappingSetName 	, name of a set as configured in the 'target' object
	 * @return {string}					, HTML representation
	 */
	DependantSelect.prototype.getDependantInput = function(mappingSetName) {
		var inputTpl;

		switch (mappingSetName) {
			case 'stringSet':
				inputTpl = viewAtomInputString.slice(0);
				break;
			case 'numberSet':
				inputTpl = viewAtomInputNumber.slice(0);
				break;
			case 'activeSet':
				inputTpl = viewAtomInputSelectActive.slice(0);
				break;
			case 'statusSet':
				inputTpl = viewAtomInputSelectStatus.slice(0);
		}

		return inputTpl.replace(/{{inputId}}/g, this.getConfig('inputId'));
	};

	/**
	 * Returns an initial HTML string where the first option is selected from the first <select>
	 * element
	 *
	 * @return {string}		, HTML representation
	 */
	DependantSelect.prototype.getHtml = function() {
		var firstOptionValue = this.getSource()[0].value,
			mappingSetName = this.getMappingSetName(firstOptionValue);

		return viewAtomDependantSelect.slice(0)
			.replace(/{{firstSelectionId}}/g, this.getConfig('firstSelectionId'))
			.replace(/{{secondSelectionId}}/g, this.getConfig('secondSelectionId'))
			.replace('{{firstSelectionTranslationKey}}',
				this.getConfig('firstSelectionTranslationKey'))
			.replace('{{secondSelectionTranslationKey}}',
				this.getConfig('secondSelectionTranslationKey'))
			.replace('{{firstSelectionOptions}}', this.getSelectionOptions(this.getSource()))
			.replace('{{secondSelectionOptions}}', this.getSecondSelectionOptions())
			.replace('{{dependantInput}}', this.getDependantInput(mappingSetName));
	};

	/**
	 * Event handler for the 'change' event fired on the first <select> element, that renders the
	 * second and thirst elements again according to the selection of the first element
	 *
	 * Please note: this method should be called after DependantSelect was added to the DOM
	 *
	 * @param  {object} $parent , jQuery wrapped DOM element that contains the <select> elements
	 * @return {undefined}
	 */
	DependantSelect.prototype.addEventHandler = function($parent) {
		var self = this,
			$firstSelect = $parent.find('#' + this.getConfig('firstSelectionId')),
			$secondSelect = $parent.find('#' + this.getConfig('secondSelectionId'));

		$firstSelect.on('change', function(ev) {
			var $input = $parent.find('#' + self.getConfig('inputId')),
				$formGroup = $input.closest('.form-group');

			ev.preventDefault();

			$secondSelect.html(self.getSecondSelectionOptions($firstSelect.val()));
			$input.val('');
			$formGroup.replaceWith(self.getDependantInput(self.getMappingSetName($firstSelect.val())));
			eventbus.publish('element-render-finish', $parent);
		});
	};

	return DependantSelect;
});
