define('components/controller/state',['require','components/controller/event-bus','helper','jquery','jscookie','lzstring'],function(require) {
	'use strict';

	var eventbus 	= require('components/controller/event-bus'),
		__ 			= require('helper'),
		$ 			= require('jquery'),
		cookies		= require('jscookie'),
		LZString	= require('lzstring'),
		storage;

	try {
		storage = window.localStorage;
	}
	catch (e) {
		if (__.is(window.console)) {
			console.warn(e);
		}
	}

	return {
		APPLICATIONNAMESPACE: undefined,
		version: undefined,
		state: {
			page: undefined,		// possible values: login, table
			level: undefined,		// possible values: see validLevels
			theme: undefined, 		// possible values: light-min
			language: undefined,	// possible values: en, de
			mobileapp: undefined	// possible values: true, false
		},
		validLevels: ['device', 'plant', 'alarm'],
		persistType: 'COOKIE',		// possible values: COOKIE, LOCALSTORAGE
		enablePersistCompression: true,
		storageSupport: undefined,
		cookieSupport: undefined,
		cookieExpireDays: 365,		// after how many days does a cookie expire
		userName: undefined,
		auth:  {
			token: undefined,
			expireDate: undefined
		},
		defaultSettings: {
			level: 'plant',
			language: 'en',
			theme: 'light-min',
			mobileapp: false
		},
		urlParams: undefined,

		init: function() {
			var self = this;

			this.version = $('meta[name][data-version]').attr('data-version');

			Object.keys(this.defaultSettings).forEach(function(setting) {
				self.state[setting] = self.defaultSettings[setting];
			});

			// remember, if window.localStorage support is available
			this.storageSupport = __.isLocalStorageSupported();

			// remember, if cookie support is available
			this.cookieSupport = __.isCookieSupported();

			eventbus.subscribe('logout', function() {
				self.onLogout();
			});

			eventbus.subscribe('login-success', function(ev, message) {
				self.onLoginSuccess(message);
			});

			eventbus.subscribe('change-application-settings', function(ev, message) {
				self.onChangeApplicationSettings(message);
			});

			eventbus.subscribe('change-store-settings', function(ev, message) {
				self.onChangeStoreSettings(message.level, message.columns);
			});

			eventbus.subscribe('reset-application-settings', function() {
				self.onResetApplicationSettings();
			});

			eventbus.subscribe('invalid-store-config', function(ev, level) {
				self.onInvalidStoreConfig(level);
			});

			return this;
		},

		onLogout: function() {
			this.reset();
		},

		onLoginSuccess: function(message) {
			if (__.is(message)) {
				// store the auth token and expire date informations
				this.auth.token = message.token;
				this.auth.expireDate = message.expireDate;
				this.saveItem('auth', this.auth);
			}

			// references the table page (devices) as current page after a successful login attempt
			this.setPage('table');
		},

		/**
		 * Takes a config object with new application settings and informs the system if any
		 * of the config values changed compared to the existing values
		 *
		 * @event  change-application-settings
		 * @param  {object} settings , contains fields selectLevel, selectLanguage and selectTheme
		 * @return {undefined}
		 */
		onChangeApplicationSettings: function(settings) {
			if (__.is(settings.selectLevel) && settings.selectLevel !== this.state.level) {
				this.setLevel(settings.selectLevel);
				eventbus.publish('state-change-level', this.getLevel());
			}

			if (__.is(settings.selectLanguage) && settings.selectLanguage !== this.getLanguage()) {
				this.setLanguage(settings.selectLanguage);
				eventbus.publish('state-change-language', this.getLanguage());
			}

			if (__.is(settings.selectTheme) && settings.selectTheme !== this.state.theme) {
				this.setTheme(settings.selectTheme);
				eventbus.publish('state-change-theme', this.getTheme());
			}
		},

		/**
		 * Should always be notified by the system of any of the store configurations is changed -
		 * the config object 'columns' can then be saved to the browsers local storage for later
		 * reference (e.g. browser window is closed and re-opened)
		 *
		 * @param  {string} level   , the level of the store - e.g. plant or device
		 * @param  {object} columns , the stores columns config object containing filters and sort order
		 * @return {undefined}
		 */
		onChangeStoreSettings: function(level, columns) {
			this.saveItem(level, columns);
		},

		/**
		 * Triggers the change application settings process and sets them back to the default
		 * values
		 *
		 * @event  reset-application-settings
		 * @return {undefined}
		 */
		onResetApplicationSettings: function() {
			this.onChangeApplicationSettings({
				selectLevel: this.defaultSettings.level,
				selectLanguage: this.defaultSettings.language,
				selectTheme: this.defaultSettings.theme
			});
		},

		/**
		 * Removes the config of the given store level from storage / cookies after the config was
		 * identified as invalid
		 *
		 * @event  invalid-store-config
		 * @param  {string} level , e.g. device / plant / alarm
		 * @return {undefined}
		 */
		onInvalidStoreConfig: function(level) {
			this.removeItem(level);
		},

		/**
		 * Sets the application wide application namespace for internal reference
		 *
		 * Please note: the application is available at the developer console through a namespace
		 * object
		 *
		 * @param {string} namespace , application namespace
		 * @return {undefined}
		 */
		setApplicationNamespace: function(namespace) {
			this.APPLICATIONNAMESPACE = namespace;
		},

		getApplicationNamespace: function() {
			return this.APPLICATIONNAMESPACE;
		},

		getState: function(name) {
			return this.state[name];
		},

		setState: function(name, value) {
			if (value === 'true') {
				value = true;
			}
			else if (value === 'false') {
				value = false;
			}

			this.state[name] = value;

			// automatically keep the new state persisted
			if (__.is(this.userName)) {
				this.saveItem('state', this.state);
			}
		},

		getPage: function() {
			return this.getState('page');
		},

		setPage: function(value) {
			this.setState('page', value);
		},

		getLevel: function() {
			return this.getState('level');
		},

		setLevel: function(value) {
			if (this.validLevels.indexOf(value) > -1) {
				this.setState('level', value);
			}
			else {
				eventbus.publish('dialog-warning', 'ERROR_LEVEL_NOT_VALID');
			}
		},

		getTheme: function() {
			return this.getState('theme');
		},

		setTheme: function(value) {
			this.setState('theme', value);
		},

		getLanguage: function() {
			return this.getState('language');
		},

		setLanguage: function(value) {
			this.setState('language', value);
		},

		getMobileApp: function() {
			return this.getState('mobileapp');
		},

		setMobileApp: function(value) {
			return this.setState('mobileapp', value);
		},

		setUserName: function(name) {
			this.userName = __.hashFnv32a(name, true);
			this.saveItem('lastUser', this.userName);
		},

		getUserName: function() {
			return this.userName;
		},

		setUrlParams: function(urlParams) {
			if (__.is(urlParams.skygate_url)) {
				urlParams.skygate_url = window.decodeURIComponent(urlParams.skygate_url);
			}

			this.urlParams = urlParams;
		},

		getAuthToken: function() {
			return this.auth.token;
		},

		getSkygateUrl: function() {
			return this.urlParams.skygate_url;
		},

		/**
		 * Persists items, decides the persistance technique by looking at the persistType
		 *
		 * @param  {string} key   	, identifier without userName prefix
		 * @param  {object} value 	, object to be persisted
		 * @return {boolean}		, true if the item could be persisted
		 */
		saveItem: function(key, value) {
			if (this.cookieSupport !== true && this.storageSupport !== true) {
				return false;
			}

			switch (this.persistType) {
				case 'COOKIE':
					return this.saveCookieItem(key, value);
				case 'LOCALSTORAGE':
					return this.saveStorageItem(key, value);
			}
			return true;
		},

		/**
		 * Retrieves items from the currently selected persistence type
		 *
		 * @param  {string} key , identifier without userName prefix
		 * @return {object}     , JSON.parse() retrieved object (false in case of disabled cookies)
		 */
		getItem: function(key) {
			if (this.cookieSupport !== true && this.storageSupport !== true) {
				return false;
			}

			switch (this.persistType) {
				case 'COOKIE':
					return this.getCookieItem(key);
				case 'LOCALSTORAGE':
					return this.getStorageItem(key);
			}
		},

		/**
		 * Remove persisted items (either delete cookie or remove from local storage)
		 *
		 * @param  {string} key , identifier without userName prefix
		 * @return {boolean}	, true if the item could be removed
		 */
		removeItem: function(key) {
			if (this.cookieSupport !== true && this.storageSupport !== true) {
				return false;
			}

			switch (this.persistType) {
				case 'COOKIE':
					return this.removeCookieItem(key);
				case 'LOCALSTORAGE':
					return this.removeStorageItem(key);
			}
			return true;
		},

		saveStorageItem: function(key, value) {
			var storageValue;

			if (!__.is(storage)) {
				return;
			}

			try {
				if (key !== 'lastUser') {
					key = this.userName + '_' + key;
				}

				if (__.isObject(value)) {
					storageValue = $.extend({}, value, {__version: this.version});
				}
				else {
					storageValue = value;
				}

				storage.setItem(key, window.JSON.stringify(storageValue));
			}
			catch (e) {
				// maybe a QUOTA_EXCEEDED_ERR, but this is not reliably used across all browsers,
				// also it is not testable -> so we handle every local storage error as possible
				// quota error
				console.error(e);
				window.localStorage.clear();
				eventbus.publish('dialog_warning', 'WARNING_LOCALSTORE_QUOTA_EXCEEDED');
				eventbus.publish('logout');
			}
		},

		getStorageItem: function(key) {
			var item;

			try {
				if (key !== 'lastUser') {
					key = this.userName + '_' + key;
 				}

 				item = storage.getItem(key);
 				delete item.__version;

 				return window.JSON.parse(item);
			}
			catch (e) {
				console.error(e);
				eventbus.publish('dialog-warning', e.message);
			}
		},

		removeStorageItem: function(key) {
			try {
				if (key !== 'lastUser') {
					key = this.userName + '_' + key;
				}

				storage.removeItem(key);
			}
			catch (e) {
				console.error(e);
				eventbus.publish('dialog-warning', e.message);
			}
		},

		/**
		 * Tries to save a given key/value pair into a cookie
		 *
		 * @param  {string} key   , identifier without username as prefix
		 * @param  {object} value , javascript object to be persisted
		 * @return {undefined}
		 */
		saveCookieItem: function(key, value) {
			var cookieValue;

			if (this.cookieSupport !== true) {
				return false;
			}

			try {
				if (key !== 'lastUser') {
					key = this.userName + '_' + key;
				}
				// add current application version to the cookie object for later reference
				if (__.isObject(value)) {
					cookieValue = $.extend({}, value, {__version: this.version});
				}
				else {
					cookieValue = value;
				}

				if (this.enablePersistCompression === true) {
					value = LZString.compressToEncodedURIComponent(window.JSON.stringify(cookieValue));
				}

				cookies.set(key, value, {
					expires: this.cookieExpireDays
				});
			}
			catch (e) {
				console.error(e);
				eventbus.publish('dialog-warning', e.message);
				eventbus.publish('logout');
			}
		},

		/**
		 * Tries to retrieve a beforehand set cookie
		 *
		 * @param  {string} key , identifier without username as prefix
		 * @return {object}     , JSON.parse() object from persisted string
		 */
		getCookieItem: function(key) {
			var cookieKey = key,
				tmp;

			try {
				if (key !== 'lastUser') {
					cookieKey = this.userName + '_' + key;
				}

				if (this.enablePersistCompression === true) {
					tmp = cookies.get(cookieKey);
					if (__.is(tmp)) {
						tmp = LZString.decompressFromEncodedURIComponent(tmp);
						tmp = window.JSON.parse(tmp);
					}
				}
				else {
					tmp = cookies.getJSON(cookieKey);
					if (!__.isObject(tmp) && key !== 'lastUser') {
						tmp = undefined;
					}
				}

				// check for cookie consistency (see setCookieItem())
				if (__.isObject(tmp) && tmp.__version !== this.version) {
					throw 'Cookie version check failed!'
						+ ' // current version: ' + this.version
						+ ' // version found: ' + tmp.__version;
				}

				// remove version information to restore originally persisted object
				if (__.isObject(tmp)) {
					delete tmp.__version;
				}
			}
			catch (e) {
				console.error(e);
				eventbus.publish('dialog-warning', 'ERROR_PARSE_COOKIE');
				// please note: to self-heal the system we delete the cookie and ensure a fresh
				// start for that specific setting
				this.removeCookieItem(key);
				return undefined;
			}

			return tmp;
		},

		/**
		 * Tries to remove a key/value pair from the cookie
		 *
		 * @param  {string} key , identifier without username as prefix
		 * @return {undefined}
		 */
		removeCookieItem: function(key) {
			try {
				if (key !== 'lastUser') {
					key = this.userName + '_' + key;
				}

				cookies.remove(key);
				return true;
			}
			catch (e) {
				console.error(e);
				eventbus.publish('dialog-warning', e.message);
				return false;
			}
		},

		/**
		 * Checks if the local storage contains valid user settings stored in the local storage or
		 * cookie - it is also verifies that the user settings auth token has not expired
		 *
		 * Please note: it is always the last user checked, that logged in successfully
		 *
		 * @return {Boolean} , true if user settings are valid - false otherwise
		 */
		hasValidUserSettingsStored: function() {
			var lastUser = this.getItem('lastUser'),
				auth;

			if (this.cookieSupport !== true && this.storageSupport !== true) {
				return false;
			}

			this.userName = lastUser || 'unknown';

			// retrieve the last active user
			auth = this.getItem('auth');

			// check if the current time in utc is not _after_ the expire date
			if (__.isNull(auth) || !__.is(auth) || new Date().getTime() > Date.parse(auth.expireDate)) {
				return false;
			}

			return true;
		},

		/**
		 * Restores previously saved user settings from the predefined persistence technique
		 * - this includes an auth token and the expire date
		 *
		 * @return {undefined}
		 */
		restoreUserFromPersistence: function() {
			var auth;

			if (this.cookieSupport !== true && this.storageSupport !== true) {
				return false;
			}

			auth = this.getItem('auth');

			if (auth !== null) {
				this.auth = auth;
			}
		},

		/**
		 * Restores a previously saved state from the predefined persistence technique
		 * - this includes state controller values and store column settings
		 *
		 * @return {undefined}
		 */
		restoreStateFromPersistence: function() {
			var self = this,
				state,
				device,
				plant,
				alarm;

			if (this.cookieSupport !== true && this.storageSupport !== true) {
				return false;
			}

			state = this.getItem('state');
			device = this.getItem('device');
			plant = this.getItem('plant');
			alarm = this.getItem('alarm');

			if ( state !== null && __.is(state) ) {
				Object.keys(state).forEach(function(s) {
					var fnName = 'set' + s.charAt(0).toUpperCase() + s.slice(1);

					// call setter of the state controller for the current value
					if (__.isFunction(self[fnName])) {
						self[fnName](state[s]);
					}

					if (s === 'theme' && s !== self.defaultSettings.theme) {
						eventbus.publish('state-change-theme', state[s]);
					}
				});
			}

			if ( device !== null && __.is(device) ) {
				eventbus.publish('restore-devices-store', device);
			}

			if ( plant !== null && __.is(plant) ) {
				eventbus.publish('restore-plants-store', plant);
			}

			if ( alarm !== null && __.is(alarm) ) {
				eventbus.publish('restore-alarms-store', alarm);
			}
		},

		/**
		 * Takes all parameters set via url and sets them in the correct order - needed system events
		 * will be added in correct order to the returned array
		 *
		 * @param  {object} urlParams , as found in the caller url
		 * @return {array}  system events that need to be fired afterwards
		 */
		applyUrlParams: function(urlParams) {
			var events = [];

			if (__.is(urlParams.level)) {
				this.setLevel(urlParams.level);
				events = __.addOnce(events, 'reconnect-user');
			}

			if (__.is(urlParams.token)) {
				this.authenticateUser(urlParams.token);
				events.push('reconnect-user');
				events.push('login-success');
			}

			if (__.is(urlParams.language)) {
				this.setLanguage(urlParams.language);
			}

			if (__.is(urlParams.theme)) {
				this.setTheme(urlParams.theme);
				events.push('state-change-theme');
			}

			if (__.is(urlParams.mobileapp)) {
				this.setMobileApp(urlParams.mobileapp);
			}

			return events;
		},

		/**
		 * If the user logged in via url parameters, the needed configuration is built here
		 *
		 * Please note: as token expire date it is always assumed that the token is valid for
		 * 14 days
		 *
		 * Please note: a user name is still needed to restore settings on a follow-up login via url,
		 * but since none is given the internal username will always be 'unknown'
		 *
		 * @param  {string} token		, a valid skygate user token
		 * @return {undefined}
		 */
		authenticateUser: function(token) {
			var currentTime = new Date();

			this.auth = {
				token: token,
				expireDate: currentTime.setDate(currentTime.getDate() + 14)
			};

			this.setUserName('unknown');
			this.saveItem('auth', this.auth);
		},

		/**
		 * Resets the state controllers internal state values back to the standard values
		 *
		 * @return {undefined}
		 */
		reset: function() {
			var self = this;

			Object.keys(this.defaultSettings).forEach(function(setting) {
				self.state[setting] = self.defaultSettings[setting];
			});

			// reference the current page as the 'login' page after a logout event
			this.state.page = 'login';
			this.auth = {
				token: undefined,
				expireDate: undefined
			};
			this.urlParams = undefined;

			// remove localstorage data
			if (this.cookieSupport === true && this.storageSupport === true) {
				this.removeItem('auth');
				// please note: all other settings shall survive, in case the same user
				// logs in again on the same browser
			}
		}
	};
});
